import React, { useContext } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { Parts as PartsForm } from '@red-build/leadcentre';
import { DEALERID, BASE_URI } from '../Constants';
import { DealerContext } from '../contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '60px',
    marginBottom: '60px',
  },
  innerSection: {
    width: '100%',
    maxWidth: '1170px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '6px 15px',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      padding: '0px 0px',
    },
  },
  section: {
    width: '60%',
    '&:nth-child(1)': {
      marginRight: '40px',
      width: '40%',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      '&:nth-child(1)': {
        margin: '0px',
        padding: '0px 20px',
        width: '100%',
      },
    },
  },
  text: {
    fontSize: '16px',
  },
  titleTop: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '44px',
    fontWeight: 'bold',
  },
  titleTopAccent: {
    fontSize: '52px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    fontWeight: 'bold',
  },
}));

const Parts = () => {
  const { globalDealer, dealerList, websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  return (
    <div className={classes.root}>
      <div className={classes.innerSection}>
        <div className={classes.section}>
          <Typography variant="h1" className={classes.titleTop}>
            NEED <span className={classes.titleTopAccent}>PARTS?</span>
          </Typography>
          <Typography variant="body2" className={classes.text}>
            We will happily assist in the service of your vehicle, leave your details and one of our
            service advisors will contact you shortly.
          </Typography>
        </div>
        <div className={classes.section}>
          <PartsForm
            variant="standard"
            masterDealerId={DEALERID}
            dealerId={DEALERID}
            dealerName={globalDealer?.name?.replace('Master', ' ')}
            activeDealerId={globalDealer?.dealerId}
            base_uri={BASE_URI}
            dealerList={dealerList}
          />
        </div>
      </div>
    </div>
  );
};

export default Parts;
