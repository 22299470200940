import React, { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import ScrollUpButton from 'react-scroll-up-button';
import { MetaTags } from '@red-build/leadcentre';
import HomeComponent from './HomeComponent/HomeComponent';
import TopBar from './TopBar/TopBar';
import Footer from './Footer/Footer';
import Showroom from './Showroom/Showroom';
import DetailedVehicle from './DetailedVehicle/DetailedVehicle';
// import VehicleFinance from './Finance/VehicleFinance';
import ContactUs from './ContactUs/ContactUs';
import BookService from './BookService/BookService';
import SpecialDeals from './SpecialDeals/SpecialDeals';
import DetailedSpecialDeals from './DetailedSpecialDeals/DetailedSpecialDeals';
import Parts from './Parts/Parts';
import UsedVehicles from './UsedVehicles/UsedVehicles';
import NotFound from './NotFound/NotFound';
import OUniverse from './OUniverse/OUniverse';
import DetailedUsedVehicle from './DetailedUsedVehicle/DetailedUsedVehicle';
import Dealers from './Dealers/Dealers';
import BookATestDrive from './BookATestDrive/BookATestDrive';
import Popi from './Legal/Popia';
import { DealerContext } from './contexts/DealerContext';
import DetailedExploreVehicle from './DetailedVehicle/DetailedExploreVehicle';
import DetailedJaecoo from './DetailedVehicle/DetailedJaecoo';
import DetailedOmodaC9 from './DetailedVehicle/DetailedOmodaC9';
import DetailedC9 from './DetailedVehicle/DetailedC9';

export default function DashboardContainerOmoda() {
  const { globalDealer } = useContext(DealerContext);
  const { pathname } = useLocation();
  return (
    <main>
      <TopBar />
      <Switch>
        <Route exact path="/" render={() => <HomeComponent />} />
        <Route exact path="/dealer/:name" render={() => <HomeComponent />} />
        <Route exact path="/show-room" render={() => <Showroom />} />
        <Route exact path="/explore-c5" render={() => <DetailedExploreVehicle />} />
        <Route exact path="/show-room/:model/:stockId" render={() => <DetailedVehicle />} />
        <Route exact path="/showroom-c9/:model/:stockId" render={() => <DetailedC9 />} />
        <Route exact path="/used-vehicles" render={() => <UsedVehicles />} />
        <Route exact path="/book-a-testdrive" render={() => <BookATestDrive />} />
        <Route exact path="/book-a-testdrive/:stockId" render={() => <BookATestDrive />} />
        <Route
          exact
          path="/book-a-testdrive/:stockId/:variantId"
          render={() => <BookATestDrive />}
        />
        <Route exact path="/used-vehicles/:make/:stockId" render={() => <DetailedUsedVehicle />} />
        <Route exact path="/book-a-service" render={() => <BookService />} />
        <Route exact path="/find-a-dealer" render={() => <Dealers />} />
        <Route exact path="/parts" render={() => <Parts />} />
        <Route exact path="/promotions" render={() => <SpecialDeals />} />
        <Route exact path="/special-deals/:dealId" render={() => <DetailedSpecialDeals />} />
        {/* <Route exact path="/apply-for-finance" component={VehicleFinance} />
        <Route exact path="/apply-for-finance/:variant/:variantId/:id" component={VehicleFinance} /> */}
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/brand/o-universe" component={OUniverse} />
        <Route exact path="/privacy-policy" render={() => <Popi />} />
        <Route exact path="/explore-j7" render={() => <DetailedJaecoo />} />
        <Route exact path="/explore-c9" render={() => <DetailedOmodaC9 />} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
      <ScrollUpButton />
      <MetaTags dealer={globalDealer} pathname={pathname} canonical="https://rolaomoda.co.za/" />
    </main>
  );
}
