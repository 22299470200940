/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { ContactUs as Form, useMetaTags } from '@red-build/leadcentre';
import BuildIcon from '@mui/icons-material/Build';
import SpeedIcon from '@mui/icons-material/Speed';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { VehicleContext } from '../contexts/VehicleContext';
import { DealerContext } from '../contexts/DealerContext';
import C5 from '../assets/OmodaAssets/c5banner.webp';
import { BASE_URI, OMODA_DEALER_ID } from '../Constants';
import Carousel from '../shared/Carousel/SharedCarousel';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 0px',
    '@media (min-width:0px) and (max-width:425px)': {
      flexWrap: 'wrap',
    },
  },
  section: {
    width: '540px',
    height: '540px',
    margin: '0px 15px',
    color: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    '@media (min-width:0px) and (max-width:425px)': {
      height: '240px',
      margin: '15px',
    },
    '@media (min-width:426px) and (max-width:1024px)': {
      height: '340px',
      margin: '15px',
    },
  },
  ImgSection: {
    width: '100%',
    height: 'calc(100vh - 63px)',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    background: `url(${C5})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    justifyContent: 'center',
    padding: '0px 100px',
    '@media (min-width:0px) and (max-width:768px)': {
      height: 'calc(50vh - 63px)',
      padding: '50px',
    },
    '@media (min-width:769px) and (max-width:1024px)': {},
  },
  SwiperSection: {
    cursor: 'pointer',
  },
  SwiperSectionActionHolder: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',

    marginBottom: '60px',
  },
  SwiperContent: {
    textAlign: 'center',
    padding: '0px 40px',
    paddingBottom: '40px',
  },
  SwiperContentHeading: {
    fontFamily: 'OmodaR',
    fontSize: '44px',
  },
  SwiperContentText: {
    fontFamily: 'OmodaR',
    fontSize: '16px',
  },
  SwiperInActive: {
    opacity: '0.1',
  },
  ImgSectionHeading: {
    fontFamily: 'OmodaL',
    fontWeight: 450,
    fontSize: '90px',
    margin: '0px',
    lineHeight: '97px',
    color: 'white',
  },
  ImgSectionHeadingSub: {
    fontSize: '30px',
    margin: '0px',
    color: 'white',
  },
  ImgSectionHeadingBtnHolder: {
    marginTop: '32px',
    marginLeft: '-20px',
    '@media (max-width: 448px)': {
      display: 'none',
    },
  },
  ImgSectionHeadingBtn: {
    fontFamily: 'OmodaR',
    fontWeight: 'lighter',
    marginLeft: '20px',
    padding: '17px 32px',
    cursor: 'pointer',
    border: '1px solid #30363a',
  },
  SwiperSectionActionBtn: {
    marginLeft: '20px',
    padding: '17px 32px',
    cursor: 'pointer',
    background: 'white',
    border: '1px solid #30363a',
  },
  contactPopUpHolder: {
    position: 'fixed',
    background: '#000000a8',
    width: '100%',
    height: '100vh',
    top: '0px',
    zIndex: '5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactPopUpHolderInner: {
    background: 'white',
    width: '40%',
    padding: '20px',
    '@media (max-width: 425px)': {
      width: '90%',
      height: '78%',
      overflowX: 'auto',
    },
  },
  Close: {
    fontFamily: 'OmodaR',
    color: 'red',
    textAlign: 'right',
    padding: '0px 0px',
    fontSize: '20px',
    cursor: 'pointer',
    paddingBottom: '10px',
  },
  mButtonHolder: {
    display: 'none',
    '@media (max-width: 448px)': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      width: '100%',
    },
  },
  mHeadingBtn: {
    display: 'flex',
    flex: 1,
    padding: 15,
    margin: '10px 20px 10px 20px',
    alignContent: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    textAlign: 'center',
    fontFamily: 'OmodaR',
    fontSize: 16,
  },
  textHeader: {
    display: 'flex',
    padding: '20px',
    paddingTop: '40px',
    width: '100%',
    fontFamily: 'OmodaL',
    fontWeight: 800,
    fontSize: '50px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    color: ({ websiteColors }) => `${websiteColors.secondaryColor}`,
  },
  HolderTop: {
    width: '100%',
    // height: 250,
    display: 'flex',
    padding: '0px 20px',
    paddingBottom: '50px',
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column',
    },
  },
  innerHolderTopItem: {
    display: 'flex',
    flex: 1,
    width: 'calc(100% / 4 - 20px)',
    minHeight: '245px',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    // borderWidth: 1,
    // borderColor: ({ websiteColors }) => `${websiteColors?.secondaryColor}`,
    boxShadow: '2px 2px 10px 1px grey',
    margin: 20,
    padding: 20,
    // borderStyle: 'solid',
    borderRadius: 10,
    '@media (min-width:0px) and (max-width:768px)': {
      width: 'calc(100% - 40px)',
    },
  },
  ctaTitle: {
    fontSize: '20px',
    color: ({ websiteColors }) => `${websiteColors?.secondaryColor}`,
  },
  h1: {
    fontFamily: 'OmodaR',
    fontWeight: 500,
    fontSize: '50px',
    marginTop: '0px',
    '@media (max-width: 600px)': {
      marginTop: '50px',
    },
  },
}));

const HomeComponent = () => {
  const history = useHistory();
  const [contactPopUp, setContactPopUp] = useState(false);
  const { newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, dealerList, websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const [slides, setSlides] = useState();
  const { updateMetaTags } = useMetaTags();
  const { pathname } = useLocation();
  let cancel;

  useEffect(() => {
    const params = {
      dealerId: globalDealer?.dealerId || OMODA_DEALER_ID,
      pageNumber: 1,
      pageSize: 25,
    };

    axios({
      method: 'GET',
      url: `${BASE_URI}/Slide`,
      params,
      cancelToken: new axios.CancelToken((c) => {
        cancel = c;
      }),
    }).then((response) => {
      const tempSlides = response.data?.list?.map((itm) => ({
        img: itm.slideImageUrl,
        isVideo: itm.isVideo,
        targetUrl: itm.url,
      }));
      setSlides(tempSlides.reverse());
    });

    updateMetaTags({
      pathname,
      title: ['Home', 'Rola Omoda'],
      description: ['Welcome to Rola Omoda & Jaecoo'],
      canonical: `https://rolaomoda.co.za/`,
    });

    return () => cancel();
  }, []);

  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data);
  };

  const handleContactPopUp = () => {
    setContactPopUp(true);
  };

  return (
    <div>
      {contactPopUp && (
        <div className={classes.contactPopUpHolder}>
          <div className={classes.contactPopUpHolderInner}>
            <div
              className={classes.Close}
              onClick={() => {
                setContactPopUp(false);
              }}
            >
              X
            </div>
            <Form
              dealers={dealerList}
              dealerType="multi_dealer"
              dealerId={globalDealer?.dealerId}
              base_uri={BASE_URI}
              leadTypeId={1}
              variantId={newVehiclesList.filter((v) => v.title === 'C5')[0].variantId}
              dealerName={globalDealer?.name?.replace('Master', ' ')}
            />
          </div>
        </div>
      )}
      <div className={classes.root}>
        {slides?.length > 0 && <Carousel slides={slides} />}
        <div
          style={{
            width: '100%',
            padding: '50px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <h1 className={classes.h1}>WELCOME TO ROLA OMODA & JAECOO</h1>
          <div className={classes.HolderTop}>
            <Link className={classes.innerHolderTopItem} to="/book-a-testdrive">
              <div>
                <SpeedIcon className={classes.ctaTitle} fontSize="large" />
                <Typography variant="h4" className={classes.ctaTitle}>
                  Book a Test Drive
                </Typography>
                <p style={{ color: 'grey', fontSize: '15px' }}>
                  Schedule your test drive: Sign up today!
                </p>
              </div>
            </Link>

            <Link className={classes.innerHolderTopItem} to="/show-room">
              <div>
                <DirectionsCarIcon className={classes.ctaTitle} fontSize="large" />
                <Typography variant="h4" className={classes.ctaTitle}>
                  View Models
                </Typography>
                <p style={{ color: 'grey', fontSize: '15px' }}>View our range</p>
              </div>
            </Link>

            <a
              className={classes.innerHolderTopItem}
              href="https://www.rola.co.za/service"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <BuildIcon className={classes.ctaTitle} fontSize="large" />
                <Typography variant="h4" className={classes.ctaTitle}>
                  Book a service
                </Typography>
                <p style={{ color: 'grey', fontSize: '15px' }}>With our specialized mechanics</p>
              </div>
            </a>

            <Link className={classes.innerHolderTopItem} to="/contact-us">
              <div>
                <ContactSupportIcon className={classes.ctaTitle} fontSize="large" />
                <Typography variant="h4" className={classes.ctaTitle}>
                  Contact
                </Typography>
                <p style={{ color: 'grey', fontSize: '15px' }}>Contact us</p>
              </div>
            </Link>
          </div>
        </div>

        <div className={classes.ImgSection}>
          <Typography variant="h2" className={classes.ImgSectionHeading}>
            C5
          </Typography>
          <Typography variant="h3" className={classes.ImgSectionHeadingSub}>
            Tech Design
          </Typography>
          <div className={classes.ImgSectionHeadingBtnHolder}>
            <button
              type="button"
              className={classes.ImgSectionHeadingBtn}
              onClick={() => {
                handleRedirect(newVehiclesList.filter((v) => v.title === 'C5')[0]);
              }}
            >
              About Model
            </button>
            <button
              type="button"
              className={classes.ImgSectionHeadingBtn}
              onClick={() => {
                handleContactPopUp();
              }}
            >
              Book a Test Drive
            </button>
          </div>
        </div>
        <div className={classes.mButtonHolder}>
          <button
            type="button"
            className={classes.mHeadingBtn}
            onClick={() => {
              handleRedirect(newVehiclesList.filter((v) => v.title === 'C5')[0]);
            }}
          >
            <Typography className={classes.buttonText}>About Model</Typography>
          </button>
          <button
            type="button"
            className={classes.mHeadingBtn}
            onClick={() => {
              handleContactPopUp();
            }}
          >
            <Typography className={classes.buttonText}>Book a Test Drive </Typography>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
