export const BASE_URI = 'https://conexa.r-e-d.co.za/api';
// export const BASE_URI = "http://localhost:56746/api"
export const SELF_URI = 'http://localhost:3000/';
export const WEPAY4CARS_URI = 'https://c2-wepay4cars-marketdealer.azurewebsites.net/api';
export const PAGE_SIZE = 20;
export const QUALIFYING_FINANCE_PERCENTAGE = 0.33;
export const DEALERID = 569;
export const MASTER_CONFIGURATOR_DEALERID = 286;
export const MASTER_DEALER_NAME = 'Rola Master';
export const MOTORGROUPID = 84; // ROLA MOTOR GROUP
export const FINAPP_URI_BASE = 'https://dz6rhlcy38qhy.cloudfront.net';
export const FINAPP_URI_APPLICATION = `${FINAPP_URI_BASE}/${DEALERID}/1/vehicle/finance/0/:leadId`;
// TODO REPLACE WITH REAL URL
export const MASTER_DOMAIN = 'https://d146e709ysqeat.cloudfront.net';
// ROLA OMODA
export const OMODA_DEALER_ID = 570;
