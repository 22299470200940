import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { ContactUs, useMetaTags } from '@red-build/leadcentre';
import { BASE_URI } from '../Constants';
import DealsFilter from '../SpecialDeals/DealsFilter';
import { DealerContext } from '../contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 15px',
  },
  innerHolder: {
    maxWidth: '1170px',
    width: '100%',
  },
  CardHolder: {
    background: 'white',
    boxShadow: '0 0 5px 0 #d7d6d6 ',
    padding: '30px 0px',
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 960px)': {
      flexDirection: 'row',
    },
  },
  CardHolder50: {
    background: 'white',
    boxShadow: '0 0 5px 0 #d7d6d6 ',
    padding: '30px 0px',
    marginTop: '0px 10px',
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '10px',
    padding: '0px 30px',
    flexDirection: 'column',
  },
  cardContent50: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '10px',
    padding: '0px 20px',
    flexDirection: 'column',
  },
  title: {
    borderLeft: ({ websiteColors }) => `3px solid${websiteColors.accentColor}`,
    padding: '5px 30px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  introParagraph: {
    fontSize: '20px',
    fontWeight: 400,
    marginBottom: '40px',
    padding: '20px',
  },
  cardContentItem: {
    display: 'flex',
    padding: '10px 0px',
    borderBottom: '2px #8080802e solid',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  CardHolderWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  accent: {
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
  tc: {
    padding: '10px',
    paddingTop: '20px',
  },
  titleTop: {
    fontSize: '20px',
    fontWeight: 'unset',
  },
  titleTopAccent: {
    fontSize: '20px',
    color: '#c7001f',
    fontWeight: 'bold',
  },
  Link: {
    border: 'none',
    borderRadius: '50px',
    padding: '10px 40px',
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      background: '  #9b161c',
      boxShadow: '0 0 5px 0  #9b161c',
    },
  },
  titleFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  headlineText: {
    fontFamily: 'OmodaR',
    fontSize: '26px',
    margin: '0px !important',
    textTransform: 'uppercase',
  },
  divider: {
    margin: '0px 10px',
    background: ({ websiteColors }) => `${websiteColors.accentColor}`,
    minWidth: '3px',
    minHeight: '35px',
  },
  imageWrapper: {
    width: '100%',
    '@media (min-width: 960px)': {
      width: '50%',
    },
  },
  contentWrapper: {
    width: '100%',
    '@media (min-width: 960px)': {
      width: '50%',
    },
    display: 'flex',
    flexDirection: 'column',
  },
}));

const DetailedSpecialDeals = () => {
  const sortDirection = 'Desc';
  const pageNumber = 1;
  const history = useHistory();
  const params = useParams();
  const [offer, setOffer] = useState();
  const { websiteColors, globalDealer } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const { deals } = DealsFilter(pageNumber, sortDirection);
  const { updateMetaTags } = useMetaTags();
  const { pathname } = useLocation();

  const formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    maximumFractionDigits: 0,
  });

  useEffect(() => {
    const selectedVehicle = deals.filter((v) => parseFloat(v.id) === parseFloat(params?.dealId))[0];
    setOffer(selectedVehicle);

    const headline = selectedVehicle?.headline;
    const variant = selectedVehicle?.variant;
    const price = formatter.format(selectedVehicle?.price);
    const description = selectedVehicle?.introParagraph;
    const imageUrl = selectedVehicle?.imageUrl;
    const dealerName =
      globalDealer?.name && typeof globalDealer.name === 'string'
        ? globalDealer.name.replace('Master', '')
        : '';

    updateMetaTags({
      pathname,
      title: [headline, variant, 'Special Deals', dealerName],
      description: [headline, variant, price, dealerName, description],
      image: imageUrl,
      canonical: `https://rolaomoda.co.za/special-deals/${params?.dealId}`,
    });
  }, [params, deals]);

  const handleRedirectFinance = (vehicle) => {
    const data = {
      vehicle,
    };
    history.push(`/apply-for-finance/${params?.variant}/${params?.variantId}/${params?.id}`, data);
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        {offer && (
          <div className={classes.CardHolder}>
            <div className={classes.imageWrapper}>
              <img alt="" src={offer?.imageUrl} width="100%" />
              <div
                className={classes.cardContent}
                style={{ paddingBottom: '30px', paddingTop: '30px' }}
              >
                {offer?.isCashDeal ? null : (
                  <>
                    <div className={classes.cardContentItem}>
                      <Typography variant="h6" className={classes.titleTop}>
                        Purchase Price
                      </Typography>
                      <Typography variant="body1" className={classes.accent}>
                        {formatter.format(offer?.price)}
                      </Typography>
                    </div>
                    <div className={classes.cardContentItem}>
                      <Typography variant="h6" className={classes.titleTop}>
                        Monthly Instalment
                      </Typography>
                      <Typography variant="body1" className={classes.accent}>
                        {formatter.format(offer?.monthlyInstallment)}
                      </Typography>
                    </div>
                    <div className={classes.cardContentItem}>
                      <Typography variant="h6" className={classes.titleTop}>
                        Term
                      </Typography>
                      <Typography variant="body1" className={classes.accent}>
                        {offer?.term}
                      </Typography>
                    </div>
                    <div className={classes.cardContentItem}>
                      <Typography variant="h6" className={classes.titleTop}>
                        Linked Interest Rate
                      </Typography>
                      <Typography variant="body1" className={classes.accent}>
                        {offer?.rate} %
                      </Typography>
                    </div>
                    <div className={classes.cardContentItem}>
                      <Typography variant="h6" className={classes.titleTop}>
                        Deposit
                      </Typography>
                      <Typography variant="body1" className={classes.accent}>
                        {offer?.depositCash
                          ? formatter.format(offer?.deposit)
                          : `${offer?.deposit} %`}
                      </Typography>
                    </div>
                    <div className={classes.cardContentItem}>
                      <Typography variant="h6" className={classes.titleTop}>
                        Balloon Payment
                      </Typography>
                      <Typography variant="body1" className={classes.accent}>
                        {offer?.balloonPayment} %
                      </Typography>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={classes.contentWrapper}>
              <div className={classes.title}>
                <div className={classes.titleFlex}>
                  <Typography variant="h4" className={classes.titleTop}>
                    {offer?.make} {offer?.model}{' '}
                  </Typography>
                  <div className={classes.divider} />
                  <Typography variant="h4" className={classes.titleTop}>
                    {offer?.isCashDeal
                      ? `${formatter.format(offer.price)}`
                      : `${formatter.format(offer.monthlyInstallment)} *PM`}
                  </Typography>
                </div>
                <button
                  type="button"
                  className={classes.Link}
                  onClick={() => {
                    handleRedirectFinance(offer);
                  }}
                >
                  Pre-Qualify
                </button>
              </div>
              <div className={classes.CardHolder50}>
                <div className={classes.title}>
                  <Typography variant="h4" className={classes.titleTop}>
                    Contact Us
                  </Typography>
                </div>
                <div className={classes.cardContent50}>
                  <ContactUs
                    dealerId={offer?.dealerId}
                    base_uri={BASE_URI}
                    leadTypeId={1}
                    offerId={offer?.offerId}
                    dealerName={offer?.dealershipName}
                  />
                </div>
              </div>
              <div>
                <Typography variant="h6" className={classes.introParagraph}>
                  {offer?.introParagraph}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </div>
      <Typography variant="body1" className={classes.tc}>
        {offer?.termsAndConditions}
      </Typography>
    </div>
  );
};

export default DetailedSpecialDeals;
