/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { ContactUs as Form } from '@red-build/leadcentre';
import { useHistory, Link } from 'react-router-dom';
import { DealerContext } from '../contexts/DealerContext';
import { VehicleContext } from '../contexts/VehicleContext';
import C5 from '../assets/OmodaAssets/download1.webp';
import logo from '../assets/OmodaAssets/omoda-logo-blue.svg.svg';
import banner from '../assets/OmodaAssets/omodaC9WebBanner.webp';
import Interior from '../assets/OmodaAssets/exterior3.webp';
import Exterior from '../assets/OmodaAssets/variant2.webp';
// eslint-disable-next-line import/no-unresolved
import Mirror from '../assets/OmodaAssets/banner4.0deb2601.jpg';
import DarkBanner from '../assets/OmodaAssets/c9-interior1.webp';
import Aircon from '../assets/OmodaAssets/dashboard1.jpg';
import EcoDrive from '../assets/OmodaAssets/inner1.webp';
import Dash from '../assets/OmodaAssets/dash1.jpg';
import { BASE_URI } from '../Constants';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 0px',
    '@media (min-width:0px) and (max-width:425px)': {
      flexWrap: 'wrap',
    },
  },
  section: {
    width: '540px',
    height: '540px',
    margin: '0px 15px',
    color: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    '@media (min-width:0px) and (max-width:425px)': {
      height: '240px',
      margin: '15px',
    },
    '@media (min-width:426px) and (max-width:1024px)': {
      height: '340px',
      margin: '15px',
    },
  },
  ImgSection: {
    width: '100%',
    height: 'calc(100vh - 63px)',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    background: `url(${C5})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    justifyContent: 'center',
    padding: '0px 100px',
    '@media (min-width:0px) and (max-width:768px)': {
      height: 'calc(50vh - 63px)',
      padding: '50px',
    },
    '@media (min-width:769px) and (max-width:1024px)': {},
  },
  vid: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
  },
  SwiperSection: {
    cursor: 'pointer',
  },
  SwiperSectionActionHolder: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',

    marginBottom: '60px',
  },
  SwiperContent: {
    textAlign: 'center',
    padding: '0px 40px',
    paddingBottom: '40px',
  },
  SwiperContentHeading: {
    fontFamily: 'OmodaR',
    fontSize: '44px',
  },
  SwiperContentText: {
    fontFamily: 'OmodaR',
    fontSize: '16px',
  },
  SwiperInActive: {
    opacity: '0.1',
  },
  ImgSectionHeading: {
    fontFamily: 'OmodaL',
    fontWeight: 450,
    fontSize: '90px',
    margin: '0px',
    lineHeight: '97px',
    color: 'white',
  },
  ImgSectionHeadingSub: {
    fontSize: '30px',
    margin: '0px',
    color: 'white',
  },
  ImgSectionHeadingBtnHolder: {
    marginTop: '32px',
    marginLeft: '-25px',
    '@media (max-width: 448px)': {
      display: 'none',
    },
  },
  ImgSectionHeadingBtn: {
    fontFamily: 'OmodaR',
    fontWeight: 'lighter',
    marginLeft: '20px',
    padding: '17px 32px',
    cursor: 'pointer',
    border: '1px solid #30363a',
  },
  SwiperSectionActionBtn: {
    marginLeft: '20px',
    padding: '17px 32px',
    cursor: 'pointer',
    background: 'white',
    border: '1px solid #30363a',
  },
  contactPopUpHolder: {
    position: 'fixed',
    background: '#000000a8',
    width: '100%',
    height: '100vh',
    top: '0px',
    zIndex: '5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactPopUpHolderInner: {
    background: 'white',
    width: '40%',
    padding: '20px',
    '@media (max-width: 425px)': {
      width: '90%',
      height: '78%',
      overflowX: 'auto',
    },
  },
  Close: {
    fontFamily: 'OmodaR',
    color: 'red',
    textAlign: 'right',
    padding: '0px 0px',
    fontSize: '20px',
    cursor: 'pointer',
    paddingBottom: '10px',
  },
  mButtonHolder: {
    display: 'none',
    '@media (max-width: 448px)': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      width: '100%',
    },
  },
  mHeadingBtn: {
    display: 'flex',
    flex: 1,
    padding: 15,
    margin: '10px 20px 10px 20px',
    alignContent: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    textAlign: 'center',
    fontFamily: 'OmodaR',
    fontSize: 16,
  },
  textHeader: {
    display: 'flex',
    padding: '20px',
    paddingTop: '40px',
    width: '100%',
    fontFamily: 'OmodaL',
    fontWeight: 800,
    fontSize: '50px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    color: ({ websiteColors }) => `${websiteColors.secondaryColor}`,
  },
  HolderTop: {
    width: '100%',
    // height: 250,
    display: 'flex',
    padding: '0px 20px',
    paddingBottom: '50px',
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column',
    },
  },
  Wrapper: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  innerHolderTopItem: {
    display: 'flex',
    flex: 1,
    width: 'calc(100% / 4 - 20px)',
    minHeight: '245px',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    // borderWidth: 1,
    // borderColor: ({ websiteColors }) => `${websiteColors?.secondaryColor}`,
    boxShadow: '2px 2px 10px 1px grey',
    margin: 20,
    padding: 20,
    // borderStyle: 'solid',
    borderRadius: 10,
    '@media (min-width:0px) and (max-width:768px)': {
      width: 'calc(100% - 40px)',
    },
  },
  ctaTitle: {
    fontSize: '20px',
    color: ({ websiteColors }) => `${websiteColors?.secondaryColor}`,
  },

  h2: {
    alignItems: 'center',
    position: 'relative',
    display: 'flex',
    padding: '4.5rem 0',
    flexDirection: 'column',
    zIndex: '1',
    backgroundColor: 'transparent',
    fontWeight: '300',
    textTransform: 'uppercase',
    fontFamily: 'OmodaR',
    fontSize: '60px',
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '10px',
      alignItems: 'center',
      fontSize: '40px',
    },
  },
  h3: {
    Width: '100%',
    paddingRight: '165px',
    fontFamily: 'OmodaR',
    color: '090909',
    paddingLeft: '250px',
    paddingBottom: '2rem',
  },
  h3F: {
    fontFamily: 'OmodaL',
    color: '090909',
    paddingBottom: '2rem',
    textAlign: 'center',
    width: '80%',
    paddingTop: '0',
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '50px',
      width: '100%',
      fontFamily: 'OmodaL',
      textAlign: 'center',
    },
  },
  h3L: {
    Width: '30%',
    maxWidth: '30%',
    fontFamily: 'OmodaR',
    color: '090909',
    paddingBottom: '2rem',
  },
  h3D: {
    Width: '100%',
    fontFamily: 'OmodaL',
    color: '090909',
    fontSize: '15px',
    '@media (min-width:0px) and (max-width:768px)': {
      fontSize: '15px',
      Width: '100%',
      fontFamily: 'OmodaL',
      alignItems: 'center',
    },
  },
  h3E: {
    Width: '100%',
    fontFamily: 'OmodaL',
    color: '090909',
    paddingBottom: '2rem',
    marginTop: '-25rem',
    '@media (min-width:0px) and (max-width:768px)': {
      paddingTop: '100px',
    },
  },

  h3A: {
    Width: '30%',
    maxWidth: '30%',
    fontFamily: 'OmodaR',
    color: '090909',
    paddingBottom: '2rem',
    fontSize: '16px!important',
    letterSpacing: '1px',
    '@media (min-width:0px) and (max-width:768px)': {
      height: 'calc(50vh - 63px)',
      padding: '50px',
    },
  },
  h3O: {
    Width: '30%',
    maxWidth: '30%',
    fontFamily: 'OmodaR',
    color: '090909',
    paddingBottom: '2rem',
    fontSize: '16px!important',
    letterSpacing: '1px',
    '@media (min-width:0px) and (max-width:768px)': {
      alignItems: 'center',
      maxWidth: '100%',
    },
  },
  em: {
    fontSize: '1em',
    textTransform: 'none',
    fontFamily: 'OmodaR',
  },

  p: {
    fontSize: '12px',
    textAlign: 'left',
    paddingRight: '40rem',
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '1px',
    },
  },
  pO: {
    fontSize: '12px',
    textAlign: 'left',
    paddingLeft: '15rem',
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '50px',
      Width: '100%!important',
      textAlign: 'left',
      fontSize: '10px',
    },
  },
  img: {
    width: '25%',
    textAlign: 'right',
    paddingRight: '20rem',
  },

  ExtOverview: {
    width: '60%',
    paddingLeft: '50px',
  },
  ExOverview: {
    width: '100%',
    paddingLeft: '-100px',
  },
  IntOverview: {
    width: '100%',
    paddingRight: '50px',
  },

  Tcontainer: {
    paddingTop: '-25rem',
    fontSize: '20px',
    letterSpacing: '1px',
  },
  Ocontainer: {
    paddingLeft: '20rem',
    fontSize: '20px',
    letterSpacing: '1px',
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '50px',
    },
  },
  Overviewcontainer: {
    paddingTop: '-450rem',
    paddingLeft: '80rem',
    fontSize: '16px!important',
    letterSpacing: '1px',
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '50px',
      paddingTop: '20rem',
    },
  },
  TextWrapper: {
    background: '#000',
    color: '#fff',
    padding: '10px 50px',
  },
  ImgWrapper: {
    background: '#000',
    color: '#fff',
    '@media (min-width:0px) and (max-width:768px)': {
      paddingTop: '10px',
    },
  },
}));

const DetailedOmodaC9 = () => {
  const history = useHistory();
  const [contactPopUp, setContactPopUp] = useState(false);
  const { globalDealer, dealerList, websiteColors } = useContext(DealerContext);
  const { newVehiclesList } = useContext(VehicleContext);
  const classes = useStyles({ websiteColors });
  const handleContactPopUp = () => {
    setContactPopUp(true);
  };
  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data);
  };
  return (
    <div>
      {contactPopUp && (
        <div className={classes.contactPopUpHolder}>
          <div className={classes.contactPopUpHolderInner}>
            <div
              className={classes.Close}
              onClick={() => {
                setContactPopUp(false);
              }}
            >
              X
            </div>
            <Form
              dealers={dealerList}
              dealerType="multi_dealer"
              dealerId={globalDealer?.dealerId}
              base_uri={BASE_URI}
              leadTypeId={1}
              variantId={newVehiclesList.filter((v) => v.model === 'C9')[0].variantId}
              dealerName={globalDealer?.name?.replace('Master', ' ')}
            />
          </div>
        </div>
      )}
      <div className={classes.root}>
        <img alt="" src={banner} width="100%" className={classes.ExOverview} />
        <div
          style={{
            width: '100%',
            padding: '50px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p className={classes.p}>INTRODUCING THE NEW OMODA C9</p>
          <h2 className={classes.h2}>
            <em className={classes.em}>THE NEXT CHAPTER OF SUV ELEGANCE</em>
          </h2>
          <div className={classes.img}>
            <Link to="/">
              <img alt="" src={logo} width="100%" className={classes.logo} />
            </Link>
          </div>
          <div className={classes.Tcontainer}>
            <h3 className={classes.h3D}>
              The automotive world is about to witness an SUV evolution.
            </h3>
          </div>
          <div className={classes.ImgSection}>
            <Typography variant="h2" className={classes.ImgSectionHeading}>
              C9
            </Typography>
            <Typography variant="h3" className={classes.ImgSectionHeadingSub}>
              SUV ELEGANCE
            </Typography>
            <div className={classes.ImgSectionHeadingBtnHolder}>
              <button
                type="button"
                className={classes.ImgSectionHeadingBtn}
                onClick={() => {
                  handleRedirect(newVehiclesList.filter((v) => v.model === 'C9')[0]);
                }}
              >
                About Model test
              </button>
              <button
                type="button"
                className={classes.ImgSectionHeadingBtn}
                onClick={() => {
                  handleContactPopUp();
                }}
              >
                Book a Test Drive
              </button>
            </div>
          </div>
          <div className={classes.mButtonHolder}>
            <button
              type="button"
              className={classes.mHeadingBtn}
              onClick={() => {
                handleRedirect(newVehiclesList.filter((v) => v.title === 'C5')[0]);
              }}
            >
              <Typography className={classes.buttonText}>About Model</Typography>
            </button>
            <button
              type="button"
              className={classes.mHeadingBtn}
              onClick={() => {
                handleContactPopUp();
              }}
            >
              <Typography className={classes.buttonText}>Book a Test Drive </Typography>
            </button>
          </div>
        </div>
        <h2 className={classes.h2}>
          ICONIC OMODA<em className={classes.em}>EXTERIOR</em>Design
        </h2>
        <span>
          <img alt="" src={Interior} className={classes.ExtOverview} />
        </span>
      </div>
      <div className={classes.Overviewcontainer}>
        <h3 className={classes.h3E}>
          {`The OMODA C9 is poised to redefine the  SUV landscape, blending cutting-edge technology with timeless elegance,
          which draws inspiration from avant-garde art to explore new concepts, colors, and forms in
          automotive design.`}
        </h3>
        <span>
          <img alt="" src={Exterior} className={classes.IntOverview} />
        </span>
      </div>
      <p className={classes.pO}>EMBARK ON AN EXCLUSIVE JOURNEY</p>
      <h2 className={classes.h2}>
        PREMIUM<em className={classes.em}>Comfort</em>
      </h2>
      <div className={classes.Ocontainer}>
        <h3 className={classes.h3F}>
          {`From sneak peeks at its sleek design to deep dives into its advanced features, you'll be at the forefront of the C9 evolution`}
        </h3>
        <div className={classes.Gallery}>{/* <Gallery images={images} /> */}</div>
      </div>
      <div className={classes.ImgWrapper}>
        <img alt="" src={Mirror} width="100%" className={classes.ExOverview} />
      </div>
      <div className={classes.ImgWrapper}>
        <img alt="" src={DarkBanner} width="100%" className={classes.ExOverview} />
        <div className={classes.TextWrapper}>
          <p className={classes.pO}>OMODA C9 TECHNOLOGY: EXPERIENCE INNOVATION</p>
          <h2 className={classes.h2}>
            Smart<em className={classes.em}>TECHNOLOGY</em>
          </h2>
          <h3 className={classes.h3O}>
            The tech-forward interior of the new OMODA boasts many advanced features, including two
            high-resolution dual 10.25inch screens, multi-color ambient interior lighting, wireless
            charging, premium acoustics, and much more.
          </h3>
          <div className={classes.SmallWrapper}>
            <img alt="" src={Aircon} width="100%" className={classes.ExOverview} />
          </div>
          <div className={classes.MediumWrapper}>
            <img alt="" src={EcoDrive} width="100%" className={classes.ExOverview} />
          </div>
          <div className={classes.ImgWrapper}>
            <img alt="" src={Dash} width="100%" className={classes.ExOverview} />
          </div>
          <div className={classes.TextWrapper}>
            <p className={classes.pO}>OMODA C9 SAFETY: ADVANCED PROTECTION FOR YOU</p>
            <h2 className={classes.h2}>
              DYNAMICS<em className={classes.em}>and SAFETY</em>
            </h2>
            <h3 className={classes.h3O}>
              {`The C9 isn't just an SUV; it's a glimpse into the future of mobility, where luxury meets innovation, and style converges with supreme elegance.`}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedOmodaC9;
