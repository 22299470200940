import React, { createContext, useState } from 'react';

export const CompareContext = createContext();

export const CompareProvider = ({ children }) => {
  const [compareVal, setCompareVal] = useState([]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CompareContext.Provider value={{ compareVal, setCompareVal }}>
      {children}
    </CompareContext.Provider>
  );
};
