/* eslint-disable no-restricted-globals */
import React, { useContext } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';
import { MetaTagsProvider } from '@red-build/leadcentre';
import DashboardContainerOmoda from './OmodaComponents/MainContainer';
// import omoda from './OmodaComponents/assets/OmodaAssets/favicon.ico';
import { DealerContext } from './OmodaComponents/contexts/DealerContext';
import { MakeProvider } from './OmodaComponents/contexts/MakeContext';
import { CompareProvider } from './OmodaComponents/contexts/CompareContext';
import { FavouriteProvider } from './OmodaComponents/contexts/FavouriteContext';
import { VehicleProvider } from './OmodaComponents/contexts/VehicleContext';
// import WhatsAppLogo from './OmodaComponents/assets/Whatsapp_Logo.png';

function App() {
  localStorage.clear();
  const { globalDealer } = useContext(DealerContext);

  // const openWhatsApp = () => {
  //   const currentLocation = window.location.href;
  //   const url = `https://wa.me/+27722147141?text=Hi, can you please assist. I am currently viewing the following page: ${currentLocation}`;
  //   window.open(url, '_blank');
  // };

  return (
    <Router>
      <FavouriteProvider>
        <CompareProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <CssBaseline />
            <VehicleProvider>
              <MakeProvider>
                <MetaTagsProvider>
                  {globalDealer && <DashboardContainerOmoda />}
                  {/* <div
                    style={{
                      position: 'fixed',
                      bottom: 120,
                      right: 50,
                      height: 30,
                      width: 30,
                      zIndex: 999,
                    }}
                  >
                    <img
                      alt="Contact Rola WhatsApp button"
                      src={WhatsAppLogo}
                      onClick={() => openWhatsApp()}
                    />
                  </div> */}
                </MetaTagsProvider>
              </MakeProvider>
            </VehicleProvider>
          </SnackbarProvider>
        </CompareProvider>
      </FavouriteProvider>
    </Router>
  );
}

export default App;
