import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { DealerContext } from '../contexts/DealerContext';
import { OMODA_DEALER_ID } from '../Constants';
import omodaLogo from '../assets/OmodaAssets/omoda-logo-blue.svg.svg';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'flex-start',
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
  },
  overlaySection: {
    alignSelf: 'end',
    width: '40%',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    alignItems: 'end',
    '@media (max-width: 959px)': {
      flexWrap: 'wrap',
      width: '100%',
      padding: '10px 10px',
    },
    zIndex: 2,
  },
  heading: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    marginLeft: 20,
    marginTop: 20,
  },
  titleTop: {
    fontFamily: 'OmodaR',
    fontSize: '44px',
    fontWeight: 'bold',
  },
  titleTopAccent: {
    fontFamily: 'OmodaB',
    fontSize: '52px',
    color: ({ accentColor }) => `${accentColor}`,
    fontWeight: 'bold',
  },
  dealerSection: {
    marginRight: 10,
    padding: '40px 40px',
    right: 0,
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    '@media (max-width: 959px)': {
      width: '100%',
      margin: '15px',
      overflowY: 'scroll',
      '&:nth-child(1)': {
        margin: '0px',
      },
    },
    overflowY: 'scroll',
    height: '90vh',
  },
  singleDealer: {
    flex: 1,
    maxHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '100%',
    marginBottom: 20,
    boxShadow: '0px 0px 30px 5px black',
    '@media (max-width: 959px)': {
      maxHeight: '1000px',
    },
  },
  dealerHeadingSection: {
    display: 'flex',
    flex: 1,
    padding: 20,
    '@media (max-width: 959px)': {
      padding: 5,
      justifyContent: 'center',
      alignSelf: 'center',
    },
  },
  dealerButton1: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#d6ebf9',
    fontWeight: 'bold',
    color: 'white!important',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'white',
      cursor: 'pointer',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      justifyContent: 'center',
      alignSelf: 'center',
      textAlign: 'center',
    },
  },
  dealerButton1Text: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    color: 'black',
    fontFamily: 'OmodaR',
    justifyContent: 'center',
    '&:hover': {
      color: 'black',
    },
  },
  dealerButton2: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    backgroundColor: ({ secondaryColor }) => `${secondaryColor}`,
    color: 'white!important',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'white',
      cursor: 'pointer',
    },
    '@media (max-width: 959px)': {
      width: '100%',
      justifyContent: 'center',
      alignSelf: 'center',
      textAlign: 'center',
    },
  },
  dealerButton2Text: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    color: 'white',
    fontFamily: 'OmodaR',
    justifyContent: 'center',
    '&:hover': {
      color: 'black',
    },
  },
  fontSmall: {
    fontFamily: 'OmodaR',
    fontSize: '0.7rem',
  },
  fontSmallHeading: {
    fontFamily: 'OmodaR',
    display: 'flex',
    fontWeight: 'bold',
    paddingBottom: 10,
    paddingTop: 10,
  },
  dealerTextHolder: {
    display: 'flex',
    flexDirection: 'column',
    flex: 3,
    padding: 20,
  },
  numberHolder: {
    display: 'flex',
    '@media (max-width: 425px)': {
      flexDirection: 'column',
    },
  },
  mapBannerHolder: {
    width: '100%',
  },
  dealerLogoSection: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    maxHeight: 80,
    paddingTop: 20,
    paddingRight: 20,
  },
  dealerInfoSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    '@media (max-width: 959px)': {
      flexDirection: 'column',
    },
  },
  dealerButtonSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    '@media (max-width: 959px)': {
      flexDirection: 'column',
    },
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  mapholder: {
    top: 0,
    position: 'absolute',
    display: 'flex',
    height: 'calc(100% - 65px)',
    flex: 1,
    flexDirection: 'column',
    zIndex: 0,
    '@media (max-width: 425px)': {
      height: 'calc(100% - 465px)',
    },
  },
}));

const cleanName = (dealerName) => dealerName.replace(/ /g, '-').toLowerCase();

const Dealers = () => {
  const { dealerList, websiteColors } = useContext(DealerContext);
  const classes = useStyles({ ...websiteColors });
  const [selectedDealer, setSelectedDealer] = useState({});
  const [filteredDealers, setFilteredDealers] = useState([]);

  useEffect(() => {
    if (dealerList) {
      // Get current dealer data
      const aDealerData = dealerList.filter((v) => parseFloat(v.dealerId) === OMODA_DEALER_ID);
      if (aDealerData.length > 0) {
        // current dealer associated brands
        const aCurrentDealerRelatedBrands = aDealerData[0].relatedBrands;

        const reducedDealerList = dealerList.reduce((ac, v) => {
          // check in dealerList for a dealer that has the same related brand as the current dealer's 0th index
          const filteredRelatedBrands = v.relatedBrands.filter(
            (vR) => vR.id === aCurrentDealerRelatedBrands[0].id
          );
          // if the reduced dealer does have a related brand that corresponds to the current dealer's, add it to the list
          if (filteredRelatedBrands.length > 0) {
            return [...ac, v];
          }
          return ac;
        }, []);
        setFilteredDealers(reducedDealerList);
        if (reducedDealerList.length === 1) {
          setSelectedDealer(reducedDealerList[0]);
        }
      } else setFilteredDealers([]);
    }
  }, [dealerList]);

  return (
    <div className={classes.root}>
      <iframe
        className={classes.mapholder}
        title="mapFrame"
        src={`https://maps.google.com/maps?q=${selectedDealer.addressLongitude},${selectedDealer.addressLatitude}&via=0&z=16&output=embed`}
        height="100%"
        width="100%"
        // this might be deprecated, but it still makes a difference.
        frameBorder="0"
      />
      <div className={classes.overlaySection}>
        <div className={classes.dealerSection}>
          {filteredDealers?.map((dealer, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={classes.singleDealer}
            >
              <div
                style={{ display: 'flex', flexDirection: 'row' }}
                onClick={() => setSelectedDealer(dealer)}
              >
                <div className={classes.dealerHeadingSection}>
                  <Typography variant="h6">{dealer.name}</Typography>
                </div>
                {/* eslint-disable-next-line react/no-array-index-key */}
                <div key={index} className={classes.dealerLogoSection}>
                  <img
                    style={{ objectFit: 'contain' }}
                    alt=""
                    // use "dealer.relatedBrands[0].logo" for other dealers. Omoda's logo has a white background, so it looks janky.
                    src={omodaLogo}
                    width="100%"
                  />
                </div>
              </div>
              <div className={classes.dealerInfoSection} onClick={() => setSelectedDealer(dealer)}>
                <div className={classes.dealerTextHolder}>
                  <div fontWeight="fontWeightBold" className={classes.fontSmallHeading}>
                    Address
                  </div>
                  <div className={classes.fontSmall}>
                    <a
                      href={`https://www.google.com/maps/@${dealer.addressLongitude},${dealer.addressLatitude},16z?entry=ttu`}
                      target="_blank"
                      style={{ fontSize: '15px' }}
                      rel="noreferrer"
                    >
                      {dealer?.address?.streetAddress} , {dealer?.address?.streetAddressPostal}
                    </a>
                  </div>
                </div>
                <div className={classes.dealerTextHolder}>
                  <div fontWeight="fontWeightBold" className={classes.fontSmallHeading}>
                    Business Hours
                  </div>
                  <div className={classes.fontSmall}>Monday - Friday: 8:00 - 17:00</div>
                  <div className={classes.fontSmall}>Saturday: 8:00 - 13:00</div>
                  <div fontWeight="fontWeightBold" className={classes.fontSmallHeading}>
                    Contact No
                  </div>
                  <div className={classes.fontSmall}>
                    <a
                      href={`tel:${dealer?.newCarsPhoneNumber}`}
                      target="_blank"
                      style={{ fontSize: '15px' }}
                      rel="noreferrer"
                    >
                      {dealer?.newCarsPhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
              <div className={classes.dealerButtonSection}>
                <div className={classes.dealerButton1}>
                  <div
                    className={classes.dealerButton1Text}
                    onClick={() => {
                      window.location.href = `/dealer/${cleanName(dealer.name)}/used-vehicles`;
                    }}
                  >
                    View Used Vehicles
                  </div>
                </div>
                <div className={classes.dealerButton2}>
                  <div
                    className={classes.dealerButton2Text}
                    onClick={() => {
                      window.location.href = `/dealer/${cleanName(dealer.name)}`;
                    }}
                  >
                    {dealer.name} Website <LaunchOutlinedIcon fontSize="small" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dealers;
