/* eslint-disable no-plusplus */
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URI, MASTER_CONFIGURATOR_DEALERID, DEALERID, MOTORGROUPID } from '../Constants';
import { DealerContext } from './DealerContext';

export const VehicleContext = createContext();

export const VehicleProvider = ({ children }) => {
  const [vehiclesList, setVehiclesList] = useState();
  const [brandedList, setBrandedList] = useState([]);
  const [newVehiclesList, setNewVehiclesList] = useState([]);
  const { globalDealer } = useContext(DealerContext);

  function removeDuplicates(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  useEffect(() => {
    const params = {
      pageNumber: 1,
      pageSize: 1000,
    };
    if (globalDealer?.dealerId !== DEALERID) {
      params.dealerId = globalDealer.dealerId;
    } else {
      params.motorGroupId = MOTORGROUPID;
    }

    axios({
      method: 'GET',
      url: `${BASE_URI}/stock`,
      params,
    })
      .then((response) => {
        const data = response?.data?.list;
        setVehiclesList(removeDuplicates(data, (it) => it.stockNo));
        setBrandedList(removeDuplicates(response.data?.list));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error fetching stock', error);
      });
  }, []);

  const getNewVehicles = async (dealerID) => {
    const params = {
      dealerId: dealerID,
      pageNumber: 1,
      pageSize: 1000,
    };
    return axios({
      method: 'GET',
      url: `${BASE_URI}/dealermodels`,
      params,
    })
      .then((response) => {
        const arr = globalDealer?.relatedBrands?.map((v) =>
          response.data?.list.filter((va) => va.makeId === v.id)
        );
        return arr.flat();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error at getNewVehicles', error);
      });
  };

  useEffect(async () => {
    if (globalDealer) {
      setNewVehiclesList([]);
      const masterVehicles = await getNewVehicles(MASTER_CONFIGURATOR_DEALERID);
      const dealerVehicles = await getNewVehicles(globalDealer?.dealerId);
      if (masterVehicles) {
        setNewVehiclesList([...masterVehicles, ...dealerVehicles]);
      }
    }
  }, [globalDealer]);

  return (
    <VehicleContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ vehiclesList, setVehiclesList, brandedList, newVehiclesList }}
    >
      {children}
    </VehicleContext.Provider>
  );
};
