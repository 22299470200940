import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
    padding: '30px 200px',
  },
  bold: {
    fontWeight: 700,
  },
  listItem: {
    listStyle: 'lower-alpha',
  },
  url: {
    color: '#7c7c7c',
    textDecoration: 'none',
    '&:hover': {
      color: '#1f2532',
      textDecoration: 'none',
    },
  },
  link: {
    backgroundColor: '#E20A17',
    marginBottom: '1rem',
    color: '#ffffff',
    fontWeight: '700',
    '&:hover': {
      textDecoration: 'none',
      opacity: 0.8,
    },
  },
  h1: {
    fontFamily: 'OmodaR',
    fontWeight: 500,
    fontSize: '50px',
    marginTop: '0px',
    '@media (max-width: 600px)': {
      marginTop: '50px',
    },
  },
}));

const Popia = () => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <h1 className={classes.h1}>PRIVACY POLICY</h1>
      <Typography gutterBottom variant="body1" color="textPrimary" fontWeight="500">
        Rola acknowledges that privacy is important. We are committed to maintaining the privacy and
        security of all information submitted to Rola. This Privacy Policy applies to all our
        services, products and our website and outlines our commitment to the users of our website.
      </Typography>
      <Typography gutterBottom variant="body1" color="textPrimary" fontWeight="500">
        If you have any questions about this policy, please feel free to contact us through our
        website or email rola@rolagrp.co.za. Except as set out below, we do not share, or sell, or
        disclose to a third party, any personally identifiable information collected on this site.
      </Typography>
      <Typography gutterBottom variant="body1" color="textPrimary" fontWeight="500">
        Here is a list of the information we collect from you, either through our web site or
        because you give it to us in some other way, and why it is necessary to collect it:
      </Typography>
      <ol>
        <li>Business and personal information</li>
        <ul className={classes.listItem}>
          <p>
            This includes basic identification and contact information, such as your name and
            contact details and also includes all information given to us in the course of your
            business and ours, such as information you give us in your capacity as our client. We
            undertake to preserve the confidentiality of the information and of the terms of our
            relationship. It is not used for any other purpose. We expect you to reciprocate this
            policy.
          </p>
          <li>This information is used:</li>
          <li>to provide you with the services which you request;</li>
          <li>for marketing our services and products;</li>
          <li>
            information which does not identify any individual may be used in a general way by us or
            third parties, to provide class information, for example relating to demographics or
            usage of a particular page or service.
          </li>
          <p>
            We keep information, which forms part of our business record for a minimum of six years.
            That is because we may need it in some way to support a claim or defence in court. That
            is also the period within which our tax collecting authorities may demand to know it.
          </p>
        </ul>
        <br />
        <li>Your domain name and e-mail address</li>
        <ul className={classes.listItem}>
          <p>
            This information is recognised by our servers and the pages that you visit are recorded.
            We shall not under any circumstances, divulge your e-mail address to any person who is
            not an employee or contractor of ours and who does not need to know, either generally or
            specifically. This information is used:
          </p>
          <li>to correspond with you or deal with you as you expect;</li>
          <li>
            in a collective way not referable to any particular individual, for the purpose of
            quality control and improvement of our site;
          </li>
          <li>
            to send you news and or information about the product or service you have either
            enquired about or purchased;
          </li>
          <li>to tell you about other products or services we offer or of sister websites.</li>
        </ul>
        <br />
        <li>Information you post on our website</li>
        <p>
          Information you send to us by posting to a forum or blog or in an email, is stored on our
          servers. We do not specifically use that information except to allow it to be read, but
          you will see in our terms and conditions that we reserve a right to use it in any way we
          decide.
        </p>
        <li>Website usage information</li>
        <p>
          We may use software embedded in our website (such as JavaScript) to collect information
          about pages you view and how you have reached them, what you do when you visit a page, the
          length of time you remain on the page, and how we perform in providing content to you. We
          do not presently associate such information with an identifiable person.
        </p>
        <li>Information we obtain from third parties</li>
        <p>
          Although we do not disclose your personal information to any third party (except as set
          out in this notice), we do receive data which is indirectly made up from your personal
          information, from software services such as Google Analytics and others. No such
          information is identifiable to you.
        </p>
        <li>Content you provide to us with a view to be used by third party</li>
        <p>
          If you provide information to us with a view to it being read, copied, downloaded or used
          by other people, we accept no responsibility for what that third party may do with it. It
          is up to you to satisfy yourself about the privacy level of every person who might see
          your information. If it is available to all the World, you have no control whatever as to
          how it is used.
        </p>
        <li>Cookies</li>
        <p>
          Cookies are small text files that are placed on your computer`s hard drive through your
          web browser when you visit any web site. They are widely used to make web sites work, or
          work more efficiently, as well as to provide information to the owners of the site.
        </p>
        <p>
          Like all other users of cookies, we may request the return of information from your
          computer when your browser requests a web page from our server. Cookies enable our web
          server to identify you to us, and to track your actions and the pages you visit while you
          use our website. The cookies we use may last for a single visit to our site (they are
          deleted from your computer when you close your browser), or may remain on your computer
          until you delete them or until a defined period of time has passed.
        </p>
        <li>Sending a message to us via our website</li>
        <p>
          When you send a message, we collect the data you have given to us in that message in order
          to obtain confirmation that you are entitled to receive the information and to provide to
          you the information you need. We record your request and our reply in order to increase
          the efficiency of our business / organisation.
        </p>
        <li>Job application and employment</li>
        <p>
          If you send us information in connection with a job application, we may keep it for up to
          three years in case we decide to contact you at a later date.
        </p>
        <p>
          If we employ you, we collect information about you and your work from time to time
          throughout the period of your employment. This information will be used only for purposes
          directly relevant to your employment. After your employment has ended, we will keep your
          file for six years before destroying or deleting it.
        </p>
        <li>Marketing information</li>
        <p>
          With your permission we pass your email address and name to selected associates whom we
          consider may provide services or products you would find useful. You may opt out of this
          service at any time by instructing us at www.rola.co.za.
        </p>
        <li>Re-marketing</li>
        <p>
          We may use re-marketing from time to time. This involves Google or some other supplier
          placing a tag or marker on your website in order to be able to serve to you an advert for
          our products / services when you visit some other website.
        </p>
        <li>Affiliate information</li>
        <p>
          This is information given to us by you in your capacity as an affiliate of us or a
          customer or client of ours. Such information is retained for business use only. We
          undertake to preserve the confidentiality of the information and of the terms of our
          relationship. It is not used for any other purpose. We expect any affiliate to agree to
          reciprocate this policy. As an exception to this, we have the right to disclose your first
          name and URL of your affiliate connection to other affiliates and to any other person or
          organisation, on and off site. The reason is solely to enable us to mention winners and
          others whose performance as an affiliate is in some way outstanding.
        </p>
        <li>Use of site by children</li>
        <p>
          We do not market to children, nor do we sell products or services for purchase by
          children. If you are under 18, you may use our site only with consent from a parent or
          guardian.
        </p>
        <li>Disclosure to Government and their agencies</li>
        <p>
          We are subject to the law like everyone else. We may be required to give information to
          legal authorities if they so request or if they have the proper authorisation such as a
          search warrant or court order.
        </p>
        <li>Compliance with the law</li>
        <p>
          This confidentiality policy has been compiled so as to comply with the law of every
          jurisdiction in which we aim to do business. If you think it fails to satisfy the law of
          your country, we would like to hear from you, but ultimately it is your choice as to
          whether you wish to use our website.
        </p>
        <li>Review or update personally identifiable information</li>
        <p>
          At any time you may review or update the personally identifiable information that we hold
          about you, by contacting us. To better safeguard your information, we will also take
          reasonable steps to verify your identity before granting access or making corrections to
          your information.
        </p>
        <li>Removal of your information</li>
        <p>
          If you wish us to remove personally identifiable information from our web site, you may
          contact us at www.rola.co.za. To better safeguard your information, we will also take
          reasonable steps to verify your identity before granting access or making corrections to
          your information.
        </p>
        <li>Data may be “processed” outside South Africa</li>
        <p>
          Our web sites are hosted in South Africa. We also use outsourced services in countries
          outside South Africa from time to time in other aspects of our business. Accordingly data
          obtained within South Africa may be “processed” outside South Africa and data obtained in
          any other country may be processed within or outside that country.
        </p>
        <li>Promotion of Access to Information Act</li>
        <p>
          The Promotion of Access to Information Act (PAIA), 2 of 2000 gives effect to the right of
          access to information held by another person and required for the exercise or protection
          of rights. Should you wish to request any information or documentation from Rola, there
          are certain procedures prescribed by PAIA that you will need to follow.
        </p>
        <p>
          To make this process easier for you we have created a manual that you can download. Please
          note the manual may be amended from time to time. As soon as any amendments have been
          finalised the latest version of the manual will be made available on this site.
        </p>
        <br />
      </ol>
    </div>
  );
};

export default Popia;
