import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Finance as Form, useMetaTags } from '@red-build/leadcentre';
import Divider from '@mui/material/Divider';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ReactComponent as EngineIcon } from '../assets/OmodaAssets/engine.svg';
import { ReactComponent as PowerIcon } from '../assets/OmodaAssets/power.svg';
import { ReactComponent as FuelIcon } from '../assets/OmodaAssets/fuel.svg';
import { ReactComponent as ScreenIcon } from '../assets/OmodaAssets/screens.svg';
import { ReactComponent as ColourIcon } from '../assets/OmodaAssets/colour.svg';
import { ReactComponent as VoiceIcon } from '../assets/OmodaAssets/voice.svg';
import { ReactComponent as WifiIcon } from '../assets/OmodaAssets/wifi.svg';
import { BASE_URI, OMODA_DEALER_ID, DEALERID } from '../Constants';
import { VehicleContext } from '../contexts/VehicleContext';
import { DealerContext } from '../contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainContainer: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  specsContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: 'white',
    marginBottom: 20,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  variantContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '90%',
  },
  imageContainer: {
    display: 'flex',
    flex: 2,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'contain',
    '@media (max-width: 820px)': {
      width: '100%',
    },
  },
  interactionContainer: {
    width: '90%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    '@media (max-width: 820px)': {
      justifyContent: 'center',
    },
  },
  titleText: {
    fontFamily: 'OmodaL',
    fontWeight: 400,
    fontSize: 60,
    color: ({ websiteColors }) => `${websiteColors.secondaryColor}`,
  },
  ctaContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 820px)': {
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
  },
  testDriveButtonContainer: {
    display: 'flex',
    flex: 2,
    minWidth: 300,
    '@media (max-width: 820px)': {
      width: '100%',
      minWidth: 200,
      alignItems: 'center',
      justifyContent: 'space-around',
    },
  },
  testDriveButton: {
    height: '100%',
    width: '30%',
    border: 'none',
    backgroundColor: ({ websiteColors }) => `${websiteColors.secondaryColor}`,
    padding: 20,
    '@media (max-width: 820px)': {
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '90%',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  downloadButtonsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    '@media (max-width: 820px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  downloadButtons: {
    display: 'flex',
    height: '100%',
    width: '50%',
    backgroundColor: 'transparent',
    border: 'none',
    flexDirection: 'row',
    alignItems: 'center',
    '@media (max-width: 820px)': {
      width: '100%',
      marginTop: 20,
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  buttonText: {
    fontFamily: 'OmodaR',
    fontSize: 15,
    color: 'white',
  },
  outlinedButtonText: {
    fontFamily: 'OmodaR',
    fontSize: 15,
    color: ({ websiteColors }) => `${websiteColors.secondaryColor}`,
    paddingLeft: 10,
  },
  subTitleContainer: {
    width: '90%',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    paddingTop: 50,
    paddingBottom: 50,
  },
  specsContainerInternal: {
    width: '90%',
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 35,
  },
  specsTile: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    minWidth: 250,
    paddingTop: 35,
    paddingBottom: 35,
  },
  subTitleText: {
    fontFamily: 'OmodaR',
    fontSize: 23,
  },
  specsText: {
    fontFamily: 'OmodaL',
    fontSize: 16,
  },
  singleVariantContainer: {
    marginBottom: 20,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    flexWrap: 'wrap-reverse',
    alignItems: 'center',
    backgroundColor: 'white',
    '@media (max-width: 820px)': {
      flexDirection: 'column-reverse',
      paddingBottom: 10,
    },
  },
  variantDetailContainer: {
    display: 'flex',
    flex: 1,
    padding: '70px 0px 70px 70px',
    flexDirection: 'column',
    backgroundColor: 'white',
    '@media (max-width: 820px)': {
      maxWidth: '320px',
      padding: '0px',
      paddingLeft: 20,
      justifyContent: 'center',
    },
  },
  variantImageContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
    padding: '70px 70px 70px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'contain',
    '@media (max-width: 820px)': {
      maxWidth: '300px',
      padding: '0px 0px 0px 0px',
    },
  },
  variantImage: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'contain',
    maxWidth: '450px',
    '@media (max-width: 820px)': {
      maxWidth: '300px',
    },
  },
  variantTitleText: {
    fontFamily: 'OmodaR',
    fontSize: 60,
    '@media (max-width: 820px)': {
      fontSize: 40,
    },
  },
  variantBrandTitleText: {
    fontFamily: 'OmodaR',
    fontSize: 27,
  },
  variantPriceText: {
    fontFamily: 'OmodaR',
    fontSize: 50,
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    '@media (max-width: 820px)': {
      fontSize: 35,
    },
  },
  icons: {
    marginLeft: 20,
    marginRight: 20,
  },
  variantIcons: {
    marginRight: 20,
  },
  ctaButtons: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 820px)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  variantEnquireButton: {
    height: '100%',
    minWidth: '280px',
    backgroundColor: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: ({ websiteColors }) => `${websiteColors.secondaryColor}`,
    padding: 15,
    margin: '5px 10px 5px 0px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  variantTestDriveButton: {
    height: '100%',
    minWidth: '280px',
    backgroundColor: ({ websiteColors }) => `${websiteColors.secondaryColor}`,
    borderWidth: '1px',
    borderStyle: 'solid',
    padding: 15,
    margin: '5px 10px 5px 0px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  contactPopUpHolder: {
    position: 'fixed',
    background: '#000000a8',
    width: '100%',
    height: '100vh',
    top: '0px',
    zIndex: '5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactPopUpHolderInner: {
    background: 'white',
    width: '40%',
    padding: '20px',
    '@media (max-width: 425px)': {
      width: '90%',
      height: '78%',
      overflowX: 'auto',
    },
  },
  Close: {
    color: 'black',
    textAlign: 'right',
    padding: '0px 0px',
    fontSize: '20px',
    cursor: 'pointer',
    paddingBottom: '10px',
  },
}));

const DetailedVehicle = () => {
  const { newVehiclesList } = useContext(VehicleContext);
  const [activeVehicles, setActiveVehicles] = useState();
  // eslint-disable-next-line no-unused-vars
  const [activeTab, setActiveTab] = useState('Highlights');
  // eslint-disable-next-line no-unused-vars
  const [tabChangeAmount, setTabChangeAmount] = useState(0);
  const [pricingList, setPricingList] = useState([]);
  const [activeVariant, setActiveVariant] = useState([]);
  const [contactPopUp, setContactPopUp] = useState(false);
  const { globalDealer, dealerList, websiteColors } = useContext(DealerContext);
  // eslint-disable-next-line no-unused-vars
  const [activeTitle, setActiveTitle] = useState({});
  const paramss = useParams();
  const classes = useStyles({ tabChangeAmount, websiteColors });
  const [filteredDealers, setFilteredDealers] = useState([]);
  const { updateMetaTags } = useMetaTags();
  const { pathname } = useLocation();

  useEffect(() => {
    if (dealerList) {
      // Get current dealer data
      const aDealerData = dealerList.filter((v) => parseFloat(v.dealerId) === OMODA_DEALER_ID);
      if (aDealerData.length > 0) {
        // current dealer associated brands
        const aCurrentDealerRelatedBrands = aDealerData[0].relatedBrands;

        const reducedDealerList = dealerList.reduce((ac, v) => {
          // check in dealerList for a dealer that has the same related brand as the current dealer's 0th index
          const filteredRelatedBrands = v.relatedBrands.filter(
            (vR) => vR.id === aCurrentDealerRelatedBrands[0].id
          );
          // if the reduced dealer does have a related brand that corresponds to the current dealer's, add it to the list
          if (filteredRelatedBrands.length > 0) {
            return [...ac, v];
          }
          return ac;
        }, []);
        setFilteredDealers(reducedDealerList);
      } else setFilteredDealers([]);
    }
  }, [dealerList]);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/DealerModelInformation/GetForDealerModel/${paramss?.stockId}`,
    })
      .then((response) => {
        setActiveVehicles(response.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error', error);
      });

    setActiveTab('Highlights');
    const params = {
      dealerModelId: paramss.stockId,
    };
    axios({
      method: 'GET',
      url: `${BASE_URI}/dealervariants`,
      params,
    })
      .then((response) => {
        setPricingList(response.data.list);
        setActiveVariant(response.data.list[0]);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error', error);
      });
  }, [paramss]);

  const handleContactPopUp = (v) => {
    setActiveVariant(v);
    setContactPopUp(true);
  };
  const history = useHistory();

  const bookATestDriveModelOnly = () => {
    history.push(`/book-a-testdrive/${paramss?.stockId}`);
  };

  const bookATestDrive = (stockID, variantID) => {
    history.push(`/book-a-testdrive/${stockID}/${variantID}`);
  };

  useEffect(() => {
    const filteredVehicle = newVehiclesList
      .flat()
      .filter((v) => parseFloat(v.id) === parseFloat(paramss?.stockId))[0];
    setActiveTitle(filteredVehicle);

    const dealerName =
      globalDealer?.name && typeof globalDealer.name === 'string'
        ? globalDealer.name.replace('Master', '')
        : '';
    updateMetaTags({
      pathname,
      title: [filteredVehicle?.model, 'New Vehicles', dealerName],
      description: [
        filteredVehicle?.model,
        filteredVehicle?.price,
        dealerName,
        filteredVehicle?.introductionDesc,
      ],
      image: activeVehicles?.contentImages[0]?.image?.imageUrl,
      canonical: `https://rolaomoda.co.za${pathname}`,
    });
  }, [paramss, newVehiclesList]);

  const formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    maximumFractionDigits: 0,
  });

  const returnVariantColour = (variantName) => {
    switch (variantName) {
      case 'C5 Tech':
        return 'Single Colour';
      case 'C5 Lux':
        return 'Double Colour';
      case 'C5 Lux S':
        return 'Double Colour with Decals';
      case 'C5 Elegance':
        return 'Double Colour';
      case 'C5 Elegance S':
        return 'Double Colour with Decals';
      default:
        return 'Single colour';
    }
  };

  const sortedList = pricingList.sort((a, b) => a.automaticPrice - b.automaticPrice);

  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        <img
          className={classes.imageContainer}
          alt=""
          src={`${activeVehicles?.contentImages[0]?.image?.imageUrl}`}
        />
        <div className={classes.interactionContainer}>
          <div className={classes.titleContainer}>
            <Typography variant="h1" className={classes.titleText}>
              THE OMODA {activeVehicles?.introductionTitle}
            </Typography>
          </div>
          <Divider
            style={{
              marginBottom: 30,
              marginTop: 10,
              height: 1,
              width: '100%',
            }}
          />
          <div className={classes.ctaContainer}>
            <div className={classes.testDriveButtonContainer}>
              <button
                type="button"
                className={classes.testDriveButton}
                onClick={() => bookATestDriveModelOnly()}
              >
                <Typography className={classes.buttonText}>Test Drive</Typography>
              </button>
            </div>
            <div className={classes.downloadButtonsContainer}>
              {activeVehicles?.brochures[0] ? (
                <button
                  onClick={() => {
                    window.open(activeVehicles?.brochures[0]?.document?.filePath, '_blank');
                  }}
                  className={classes.downloadButtons}
                  type="button"
                >
                  <FileDownloadOutlinedIcon />
                  <Typography
                    className={classes.outlinedButtonText}
                  >{` Download Specifications`}</Typography>
                </button>
              ) : null}
              {activeVehicles?.brochures[1] ? (
                <button
                  onClick={() => {
                    window.open(activeVehicles?.brochures[1]?.document?.filePath, '_blank');
                  }}
                  className={classes.downloadButtons}
                  type="button"
                >
                  <FileDownloadOutlinedIcon />
                  <Typography
                    className={classes.outlinedButtonText}
                  >{` Download Brochure`}</Typography>
                </button>
              ) : null}
            </div>
          </div>
          <Divider
            style={{
              marginBottom: 30,
              marginTop: 30,
              height: 1,
              width: '100%',
            }}
          />
        </div>
      </div>
      {contactPopUp && (
        <div className={classes.contactPopUpHolder}>
          <div className={classes.contactPopUpHolderInner}>
            <div
              className={classes.Close}
              onClick={() => {
                setContactPopUp(false);
              }}
            >
              X
            </div>
            <Form
              leadTypeId={1}
              masterDealerId={DEALERID}
              dealerId={OMODA_DEALER_ID}
              variantId={activeVariant.variantId}
              pqType="no_pq"
              activeDealerId={globalDealer?.dealerId}
              dealers={filteredDealers}
              base_uri={BASE_URI}
              dealerType="single_dealer"
              dealerMakesOnly
              financeQuestions={[]}
              activeMakeId={
                globalDealer?.relatedBrands.length > 1 ? 0 : globalDealer?.relatedBrands[0].id
              }
              overrideLeadType
            />
          </div>
        </div>
      )}
      <div className={classes.specsContainer}>
        <div className={classes.subTitleContainer}>
          <Typography className={classes.subTitleText}>Specifications Overview</Typography>
        </div>
        <Divider
          style={{
            width: '90%',
          }}
        />
        <div className={classes.specsContainerInternal}>
          <div className={classes.specsTile}>
            <PowerIcon className={classes.icons} />
            <Typography className={classes.specsText}> 115kW Power </Typography>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className={classes.specsTile}>
            <EngineIcon className={classes.icons} />
            <Typography className={classes.specsText}> 1.5L Engine </Typography>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className={classes.specsTile}>
            <WifiIcon className={classes.icons} />
            <Typography className={classes.specsText}>Wireless Car Play & Android Auto</Typography>
          </div>
        </div>
        <Divider
          style={{
            width: '90%',
          }}
        />
        <div className={classes.specsContainerInternal}>
          <div className={classes.specsTile}>
            <FuelIcon className={classes.icons} />
            <Typography className={classes.specsText}> 6.9 L/100km Fuel Consumption </Typography>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className={classes.specsTile}>
            <VoiceIcon className={classes.icons} />
            <Typography className={classes.specsText}> Intelligent Voice Command </Typography>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className={classes.specsTile}>
            <ScreenIcon className={classes.icons} />
            <Typography className={classes.specsText}>
              {`Two high-resolution dual 10.25" screens`}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.variantContainer}>
        <div className={classes.subTitleContainer}>
          <Typography className={classes.subTitleText}>Variants</Typography>
        </div>
        {pricingList.length > 0
          ? sortedList.map((v, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className={classes.singleVariantContainer}>
                <div className={classes.variantDetailContainer}>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <Typography
                      className={classes.variantBrandTitleText}
                    >{`${v.makeName.toUpperCase()} ${v.modelName}`}</Typography>
                  </div>
                  <div style={{ display: 'flex', flex: 3 }}>
                    <Typography className={classes.variantTitleText}>{`${v.title
                      .replace('C5', '')
                      .toUpperCase()}`}</Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flex: 2,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      width: '80%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flex: 2,
                        flexDirection: 'column',
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <Typography className={classes.specsText}>Engine</Typography>
                      <div
                        style={{ display: 'flex', flex: 1, flexDirection: 'row', minWidth: 150 }}
                      >
                        <EngineIcon className={classes.variantIcons} />
                        <Typography className={classes.specsText}> 1.5T </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flex: 2,
                        flexDirection: 'column',
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <Typography className={classes.specsText}>Colour</Typography>
                      <div
                        style={{ display: 'flex', flex: 2, flexDirection: 'row', minWidth: 220 }}
                      >
                        {/* <EngineIcon className={classes.variantIcons} /> */}
                        <ColourIcon
                          className={classes.variantIcons}
                          style={{ width: '30px', height: '30px' }}
                        />
                        <Typography className={classes.specsText}>
                          {returnVariantColour(v.title)}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flex: 2 }}>
                    <Typography className={classes.variantPriceText}>
                      {formatter.format(parseFloat(v.priceRange))}
                    </Typography>
                  </div>

                  <div className={classes.ctaButtons}>
                    <button
                      className={classes.variantEnquireButton}
                      type="button"
                      onClick={() => handleContactPopUp(v)}
                    >
                      <Typography>Enquire</Typography>
                    </button>
                    <button
                      className={classes.variantTestDriveButton}
                      type="button"
                      onClick={() => bookATestDrive(v.modelId, v.variantId)}
                    >
                      <Typography style={{ color: 'white' }}>Book a Test Drive</Typography>
                    </button>
                  </div>
                </div>
                <div className={classes.variantImageContainer}>
                  <img className={classes.variantImage} alt="" src={`${v?.image}`} />
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default DetailedVehicle;
