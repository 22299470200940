import React, { createContext, useState, useEffect, useContext } from 'react';
import { DealerContext } from './DealerContext';

export const MakeContext = createContext();

export const MakeProvider = ({ children }) => {
  const getItem = (itm) => {
    const data = localStorage.getItem(itm);

    if (data && data !== 'undefined') return JSON.parse(data);
    return {};
  };
  const setItem = (name, object) => {
    const stringObject = JSON.stringify(object);
    localStorage.setItem(name, stringObject);
  };

  const [make, setMake] = useState(getItem('activeMake'));
  const { websiteColors } = useContext(DealerContext);
  const [makeColors, setMakeColors] = useState({
    primaryColor: make?.primaryColor ? make?.primaryColor : websiteColors?.primaryColor,
    secondaryColor: make?.secondaryColor ? make?.secondaryColor : websiteColors?.secondaryColor,
    accentColor: make?.accentColor ? make?.accentColor : websiteColors?.accentColor,
    logo: make?.logo ? make?.logo : websiteColors?.logo,
  });

  const setActiveGlobalMake = (activeMake) => {
    setItem('activeMake', activeMake);
    setMake(activeMake);
  };

  useEffect(() => {
    if (make?.name) {
      const colors = {
        primaryColor: make?.primaryColor ? make?.primaryColor : websiteColors?.primaryColor,
        secondaryColor: make?.secondaryColor ? make?.secondaryColor : websiteColors?.secondaryColor,
        accentColor: make?.accentColor ? make?.accentColor : websiteColors?.accentColor,
        logo: make?.logo ? make?.logo : websiteColors?.logo,
      };
      setMakeColors(colors);
    } else {
      setMake(null);
    }
  }, [make]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <MakeContext.Provider value={{ make, makeColors, setActiveGlobalMake, setMake }}>
      {children}
    </MakeContext.Provider>
  );
};
