import React, { createContext, useState } from 'react';

export const FavouriteContext = createContext();

export const FavouriteProvider = ({ children }) => {
  const [favouriteVal, setFavouriteVal] = useState([]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FavouriteContext.Provider value={{ favouriteVal, setFavouriteVal }}>
      {children}
    </FavouriteContext.Provider>
  );
};
