import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';

import { DealerContext } from '../contexts/DealerContext';
import logo from '../assets/OmodaAssets/Omoda Jaecoo Somerset West LOGO_Rola Omoda and Jaecoo Somerset West Light Logo PNG.png';
import RolaLogo from '../assets/Rola_Black_Logo.png';
import { DEALERID } from '../Constants';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 5,
    bottom: 0,
  },
  bottomSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0px 0px',
    flexDirection: 'column',
    marginBottom: '0px',
    padding: '0px 100px',
    background: '#30363a',
    '@media (min-width:0px) and (max-width:768px)': {
      padding: '20px 0px',
    },
    zIndex: 4,
  },
  innerHolder: {
    width: '100%',
    margin: '0 auto',
    padding: '0px 40px',
    justifyContent: 'space-evenly',
    '@media (min-width:0px) and (max-width:768px)': {
      flexDirection: 'column',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(166,183,190,.3)',
    padding: '32px 0px',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '15px',
      flexDirection: 'column',
    },
  },
  sectionMid: {
    display: 'flex',
    flexDirection: 'row',
    color: 'white',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(166,183,190,.3)',
    padding: '30px 0px',
    paddingBottom: '10px',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '15px',
      flexDirection: 'column',
    },
  },
  sectionFlex: {
    display: 'flex',
    '@media (min-width:769px) and (max-width:1024px)': {
      flexDirection: 'column',
    },
  },
  sectionInner: {
    display: 'flex',
    '@media (min-width:0px) and (max-width:768px)': {
      margin: '5px 0px',
      flexDirection: 'column',
    },
    '@media (min-width:769px) and (max-width:1024px)': {
      flexDirection: 'column',
    },
  },
  sectionInnerGroup: {
    width: 'calc(100%/2)',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '0px 15px',
    },
  },
  GroupedSection: {
    display: 'flex',
    width: 'calc(100%/3)',
    flexDirection: 'column',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '0px 15px',
    },
  },
  heading: {
    fontFamily: 'OmodaR',
    color: '#6b777c',
    fontSize: '24px',
  },
  headingText: {
    fontFamily: 'OmodaR',
    fontSize: '16px!important',
    margin: '10px 0px',
    color: 'white',
  },
  headingTextFlex: {
    fontFamily: 'OmodaR',
    flexDirection: 'column',
    display: 'flex',
    color: 'white',
    alignItems: 'end',
    '@media (max-width: 768px)': {
      alignItems: 'start',
    },
  },
  imgSection: {
    width: 'calc(100%/3)',
    textAlign: 'center',
    '@media (min-width:0px) and (max-width:768px)': {
      width: '100%',
      padding: '0px 15px',
    },
  },
  hr: {
    border: '1px #6c757d70 solid',
    width: '100%',
  },
  legal: {
    fontFamily: 'OmodaR',
    fontSize: '8pt',
  },
  socialLinkHolder: {
    display: 'flex',
    '@media (min-width:0px) and (max-width:768px)': {
      margin: '5px 0px',
    },
  },
  socialLink: {
    padding: '0px 5px',
    color: 'black!important',
    '@media (min-width:769px) and (max-width:1024px)': {
      width: 'calc(100%/6)!important',
    },
  },
  socialLinkIcon: {
    width: '30px!important',
    height: '30px!important',
  },
  img: {
    display: 'flex',
    height: '100%',
    alignItems: 'end',
    '@media (max-width: 768px)': {
      width: '100%',
      margin: '0px 0px',
    },
    '@media (min-width: 769px) and (max-width: 1200px)': {
      width: '60%',
    },
  },
  logo: {
    width: '225px',
    height: '91px',
    objectFit: 'contain',
    '@media (max-width: 768px)': {
      width: '150px',
    },
    '@media (min-width: 769px) and (max-width: 1200px)': {
      width: '200px',
    },
  },
  sectionLink: {
    fontFamily: 'OmodaR',
    margin: '0px 10px',
    color: 'white',
    fontSize: '14px',
    '@media (max-width: 768px)': {
      width: '50%',
      margin: '5px 0px',
    },
  },
  sectionLinkB: {
    fontFamily: 'OmodaR',
    margin: '0px 10px',
    color: 'white',
    fontSize: '14px',
  },
  sectionLinkBL: {
    fontFamily: 'OmodaR',
    margin: '0px 10px',
    color: '#a6b7be',
    fontSize: '14px',
    '@media (max-width: 768px)': {
      width: '100%',
      margin: '5px 0px',
    },
  },
  LinkM: {
    lineHeight: 1.4,
    fontSize: '16px',
    font: '1rem OmodaL',
    fontFamily: 'OmodaL',
    fontWeight: 700,
    padding: '0px 15px',
    color: '#0c0c0b',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  masterLogoImg: {
    width: '100%',
    maxWidth: '250px',
    maxHeight: '50px',
  },
}));

const Footer = () => {
  const { dealerSocials, globalDealer, websiteColors, dealerListDirty } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });

  const goToMaster = (domain) => {
    window.location.href = domain;
  };

  const [masterDealer, setMasterDealer] = useState({});

  useEffect(() => {
    setMasterDealer(dealerListDirty?.filter((d) => d.dealerId === DEALERID)[0]);
    // setDealerData(dealerListDirty?.filter((d) => d.dealerId === globalDealer?.dealerId)[0]);
  }, [dealerListDirty]);

  return (
    <div className={classes.root}>
      <div className={classes.bottomSection}>
        <div className={classes.innerHolder}>
          <div className={classes.section}>
            <div className={classes.img}>
              <Link to="/">
                <img alt="" src={logo} width="100%" className={classes.logo} />
              </Link>
            </div>
            <div>
              <div className={classes.headingTextFlex}>
                <a
                  href={`tel:${globalDealer?.newCarsPhoneNumber?.replaceAll(' ', '')}`}
                  target="_blank"
                  className={classes.sectionLinkBL}
                  rel="noreferrer"
                >
                  Contact Us: {globalDealer?.newCarsPhoneNumber}
                </a>
                <a
                  href={`https://www.google.com/maps/@${globalDealer?.addressLongitude},${globalDealer?.addressLatitude},16z?entry=ttu`}
                  target="_blank"
                  className={classes.sectionLinkBL}
                  rel="noreferrer"
                >
                  {globalDealer?.address?.postalAddress}, {globalDealer?.address?.postalCode}
                </a>
              </div>
            </div>
          </div>
          <div className={classes.sectionMid}>
            <div className={classes.sectionInner}>
              <Link to="/book-a-testdrive" className={classes.sectionLink}>
                Book a Test Drive
              </Link>
              <Link to="/contact-us" className={classes.sectionLink}>
                Contact Us
              </Link>
              <Link to="/show-room" className={classes.sectionLink}>
                Omoda C5
              </Link>
              <a
                href="https://www.rola.co.za/service"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.sectionLink}
              >
                Book a Service
              </a>
              <a
                href="https://www.rola.co.za/parts"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.sectionLink}
              >
                Request Parts
              </a>
              <Link to="/promotions" className={classes.sectionLink}>
                Our Promotions
              </Link>
            </div>
            <div className={classes.socialLinkHolder}>
              {dealerSocials?.facebook && (
                <div className={classes.socialLink}>
                  <a
                    href={dealerSocials.facebook}
                    target="_blank"
                    style={{ color: 'white' }}
                    rel="noreferrer"
                  >
                    <FacebookIcon className={classes.socialLinkIcon} />
                  </a>
                </div>
              )}
              {dealerSocials?.instagram && (
                <div className={classes.socialLink}>
                  <a
                    href={dealerSocials.instagram}
                    target="_blank"
                    style={{ color: 'white' }}
                    rel="noreferrer"
                  >
                    <InstagramIcon className={classes.socialLinkIcon} />
                  </a>
                </div>
              )}
              {dealerSocials?.twitter && (
                <div className={classes.socialLink}>
                  <a
                    href={dealerSocials.twitter}
                    target="_blank"
                    style={{ color: 'white' }}
                    rel="noreferrer"
                  >
                    <TwitterIcon className={classes.socialLinkIcon} />
                  </a>
                </div>
              )}
              {dealerSocials?.linkedIn && (
                <div className={classes.socialLink}>
                  <a
                    href={dealerSocials.linkedIn}
                    target="_blank"
                    style={{ color: 'white' }}
                    rel="noreferrer"
                  >
                    <LinkedInIcon className={classes.socialLinkIcon} />
                  </a>
                </div>
              )}
              <div className={classes.socialLink}>
                <a
                  href="https://www.youtube.com/user/RolaMotorGroup/videos"
                  target="_blank"
                  style={{ color: 'white' }}
                  rel="noreferrer"
                >
                  <YouTubeIcon className={classes.socialLinkIcon} />
                </a>
              </div>
            </div>
          </div>
          <div className={classes.sectionMid}>
            <div className={classes.sectionInner}>
              <p className={classes.sectionLinkBL}>
                © {new Date().getFullYear().toString()} Rola OMODA
              </p>
            </div>
            <div className={classes.sectionInner}>
              <Link to="/privacy-policy" className={classes.sectionLinkB}>
                Privacy Policy
              </Link>
            </div>
            {masterDealer?.dealerId !== globalDealer?.dealerId && (
              <div
                className={classes.LinkM}
                onClick={() => goToMaster(`https://${masterDealer?.websiteUrl}`)}
              >
                <img alt="Rola_logo" className={classes.masterLogoImg} src={RolaLogo} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
