import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { FinanceProvider } from './OmodaComponents/contexts/FinanceContext';
import { DealerProvider } from './OmodaComponents/contexts/DealerContext';
import './OmodaComponents/assets/fonts/omoda-regular.ttf';
import './OmodaComponents/assets/fonts/omoda-bold.ttf';
import './OmodaComponents/assets/fonts/omoda-light.ttf';
import './OmodaComponents/assets/fonts/omoda-ultralight.ttf';

ReactDOM.render(
  <React.StrictMode>
    <FinanceProvider>
      <DealerProvider>
        <App />
      </DealerProvider>
    </FinanceProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
