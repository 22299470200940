import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import { ContactUs, useMetaTags } from '@red-build/leadcentre';
import axios from 'axios';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { VehicleContext } from '../contexts/VehicleContext';
import { BASE_URI } from '../Constants';
import { DealerContext } from '../contexts/DealerContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '80px 15px',
    background: 'whitesmoke',
  },
  innerHolder: {
    maxWidth: '1170px',
    width: '100%',
  },
  CardHolder: {
    background: 'white',
    boxShadow: '0 0 5px 0 #d7d6d6 ',
    padding: '30px 0px',
    marginTop: '20px',
  },
  CardHolder50: {
    background: 'white',
    boxShadow: '0 0 5px 0 #d7d6d6 ',
    padding: '30px 0px',
    marginTop: '0px 10px',
    width: 'calc(100%/2 - 10px)',
    '@media (max-width: 959px)': {
      width: 'calc(100%)',
    },
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '10px',
    padding: '0px 30px',
    flexDirection: 'column',
  },
  cardContent50: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '10px',
    padding: '0px 0px',
    flexDirection: 'column',
  },
  title: {
    borderLeft: ({ websiteColors }) => `3px ${websiteColors.accentColor} solid`,
    padding: '5px 30px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
    },
  },
  cardContentItem: {
    display: 'flex',
    padding: '10px 0px',
    borderBottom: '2px #8080802e solid',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  CardHolderWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  accent: {
    color: ({ websiteColors }) => `${websiteColors.accentColor} `,
  },
  tc: {
    padding: '10px',
    paddingTop: '20px',
  },
  titleTop: {
    fontSize: '20px',
    fontWeight: 'unset',
  },
  titleTopAccent: {
    fontSize: '20px',
    color: '#c7001f',
    fontWeight: 'bold',
  },
  cardContentFeature: {
    height: 'fit-content',
    boxShadow: '2px 0px 15px -2px #adadad',
    borderRadius: '2px',
    margin: '0px 10px',
    marginBottom: '10px',
    padding: '10px',
    background: 'white',
    width: 'calc(100% / 1 - 20px )',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  ImgHolder: {
    display: 'flex',
    marginTop: '20px',
    '@media (max-width: 760px)': {
      flexDirection: 'column',
    },
  },
  ImgHolderMain: {
    position: 'relative',
  },
  ImgMain: {
    aspectRatio: '16/9',
    objectFit: 'contain',
  },
  ImgGalleryHolder: {
    position: 'relative',
  },
  ImgGalleryHolderInner: {
    display: 'flex',
    transform: ({ galleryPos }) => `translate(${galleryPos}%,0%)`,
  },
  GalleryImg: {
    minWidth: 'calc(100% / 6 - 10px)',
    margin: '5px',
    width: '100%',
    height: '90px',
  },
  featureHolder: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '30%',
    margin: '0px',
    '@media (max-width: 760px)': {
      marginTop: '20px',
      width: 'unset',
    },
  },
  img: {
    width: '70%',
    overflow: 'hidden',
    '@media (max-width: 760px)': {
      width: 'unset',
    },
  },
  imgWide: {
    width: '100%',
    overflow: 'hidden',
    '@media (max-width: 760px)': {
      width: 'unset',
    },
  },
  Link: {
    border: 'none',
    borderRadius: '50px',
    padding: '10px 40px',
    background: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      background: '  #9b161c',
      boxShadow: '0 0 5px 0  #9b161c',
    },
  },
  titleFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: '0px 10px',
    background: ({ websiteColors }) => `${websiteColors.accentColor} `,

    minWidth: '3px',
    minHeight: '35px',
  },
  nextbtn: {
    background: '#d7d6d6',
    border: 'none',
    right: '0',
    padding: '10px',
    color: 'black',
    opacity: '0.5',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0px, -50%)',
    '&:hover': {
      background: '#b81b22',
    },
  },
  backbtn: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0px, -50%)',
    background: '#d7d6d6',
    border: 'none',
    left: '0',
    padding: '10px',
    color: 'black',
    opacity: '0.5',
    '&:hover': {
      background: '#b81b22',
    },
  },
}));

const DetailedUsedVehicle = () => {
  const [activeVehicle, setActiveVehicle] = useState();
  const history = useHistory();
  const [activeGallery, setActiveGallery] = useState([]);
  const [activeGalleryIndex, setActiveGalleryIndex] = useState(0);
  const [galleryPos, setGalleryPos] = useState(0);
  const { websiteColors, globalDealer } = useContext(DealerContext);
  const params = useParams();
  const classes = useStyles({ galleryPos, websiteColors });
  const { vehiclesList } = useContext(VehicleContext);
  const { updateMetaTags } = useMetaTags();
  const { pathname } = useLocation();

  const formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    maximumFractionDigits: 0,
  });

  useEffect(() => {
    setActiveVehicle(vehiclesList?.filter((v) => v.stockId === params?.stockId)[0]);
  }, [vehiclesList]);

  useEffect(() => {
    let image = '';
    axios({
      method: 'GET',
      url: `${BASE_URI}/stock/${params?.stockId}`,
    })
      .then((response) => {
        setActiveGallery(response.data.mainUrls);
        // eslint-disable-next-line prefer-destructuring
        image = response.data.mainUrls[0];
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn('There was an error', error);
      });

    const dealerName =
      globalDealer?.name && typeof globalDealer.name === 'string'
        ? globalDealer.name.replace('Master', '')
        : '';

    updateMetaTags({
      pathname,
      title: [activeVehicle?.model, 'Special Deals', dealerName],
      description: [
        activeVehicle?.model,
        formatter.format(activeVehicle?.price),
        dealerName,
        activeVehicle?.colour,
      ],
      image,
    });
  }, [activeVehicle]);

  const handleBack = () => {
    history.push('/pre-owned');
  };

  const handleGalleryNext = () => {
    if (Math.abs(galleryPos) < Math.abs(((activeGallery.length - 1) / 2) * 16.66)) {
      setGalleryPos(galleryPos - 16);
    }
  };

  const handleGalleryBack = () => {
    if (galleryPos < 0) {
      setTimeout(() => {
        setGalleryPos(galleryPos + 16);
      }, 100);
    }
  };

  const handleGalleryIndex = (index) => {
    setActiveGalleryIndex(index);
  };

  const handleBackClick = () => {
    if (activeGalleryIndex > 0) {
      setActiveGalleryIndex(activeGalleryIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (activeGalleryIndex < activeGallery.length - 1) {
      setActiveGalleryIndex(activeGalleryIndex + 1);
    }
  };

  const handleRedirectFinance = (vehicle) => {
    const data = {
      vehicle,
    };
    history.push(`/apply-for-finance/`, data);
  };

  return (
    <div className={classes.root}>
      <div className={classes.innerHolder}>
        <button
          type="button"
          className={classes.Link}
          onClick={() => {
            handleBack();
          }}
        >
          Back
        </button>
        <div className={classes.CardHolder}>
          <div className={classes.title}>
            <div className={classes.titleFlex}>
              <Typography variant="h4" className={classes.titleTop}>
                {activeVehicle?.model}
              </Typography>
              <div className={classes.divider} />
              <Typography variant="h4" className={classes.titleTop}>
                {formatter.format(activeVehicle?.price)}
              </Typography>
            </div>
            <button
              type="button"
              className={classes.Link}
              onClick={() => {
                handleRedirectFinance(activeVehicle);
              }}
            >
              Pre-Qualify
            </button>
          </div>
        </div>
        <div className={classes.ImgHolder}>
          <div className={activeVehicle?.features !== '' ? classes.img : classes.imgWide}>
            <div className={classes.ImgHolderMain}>
              <img
                alt=""
                src={activeGallery[activeGalleryIndex]}
                width="100%"
                className={classes.ImgMain}
              />
              <button
                type="button"
                onClick={() => {
                  handleBackClick();
                }}
                className={classes.backbtn}
              >
                <ArrowBackIosNewIcon />
              </button>
              <button
                type="button"
                onClick={() => {
                  handleNextClick();
                }}
                className={classes.nextbtn}
              >
                <ArrowForwardIosIcon />
              </button>
            </div>
            <div className={classes.ImgGalleryHolder}>
              <div className={classes.ImgGalleryHolderInner}>
                {activeGallery.map((i, index) => (
                  <div
                    className={classes.GalleryImg}
                    onClick={() => {
                      handleGalleryIndex(index);
                    }}
                  >
                    <img alt="" src={i} width="100%" />
                  </div>
                ))}
              </div>
              <button
                type="button"
                onClick={() => {
                  handleGalleryBack();
                }}
                className={classes.backbtn}
              >
                <ArrowBackIosNewIcon />
              </button>
              <button
                type="button"
                onClick={() => {
                  handleGalleryNext();
                }}
                className={classes.nextbtn}
              >
                <ArrowForwardIosIcon />
              </button>
            </div>
          </div>
          {activeVehicle?.features !== '' && (
            <ul className={classes.featureHolder}>
              {activeVehicle?.features.split(',').map((f, index) => {
                if (f !== ' ' && index < 10) {
                  return <li className={classes.cardContentFeature}>{f}</li>;
                }
                return null;
              })}
            </ul>
          )}
        </div>
        <div className={classes.CardHolderWrap}>
          <div className={classes.CardHolder50}>
            <div className={classes.cardContent}>
              {/* USE A MAP HERE */}
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Purchase Price
                </Typography>
                <Typography variant="body1" className={classes.accent}>
                  {formatter.format(activeVehicle?.price)}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Year
                </Typography>
                <Typography variant="body1" className={classes.accent}>
                  {activeVehicle?.year}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Mileage
                </Typography>
                <Typography variant="body1" className={classes.accent}>
                  {activeVehicle?.mileage} km
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Fuel type
                </Typography>
                <Typography variant="body1" className={classes.accent}>
                  {activeVehicle?.fuelType === 'P' ? 'Petrol' : 'Diesel'}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Transmission
                </Typography>
                <Typography variant="body1" className={classes.accent}>
                  {activeVehicle?.transmission === 'M' ? 'Manual' : 'Automatic'}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Colour
                </Typography>
                <Typography variant="body1" className={classes.accent}>
                  {activeVehicle?.colour}
                </Typography>
              </div>
              <div className={classes.cardContentItem}>
                <Typography variant="h6" className={classes.titleTop}>
                  Stock No #
                </Typography>
                <Typography variant="body1" className={classes.accent}>
                  {activeVehicle?.stockNo}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.CardHolder50}>
            <div className={classes.title}>
              <Typography variant="h4" className={classes.titleTop}>
                Contact Us
              </Typography>
            </div>
            <div className={classes.cardContent50}>
              <ContactUs
                dealerId={activeVehicle?.dealerId}
                base_uri={BASE_URI}
                leadTypeId={2}
                dealerName={activeVehicle?.dealershipName}
              />
            </div>
          </div>
        </div>
      </div>
      <Typography variant="body1" className={classes.tc}>
        {activeVehicle?.termsAndConditions}
      </Typography>
    </div>
  );
};

export default DetailedUsedVehicle;
