import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
// import { VehicleContext } from '../contexts/VehicleContext';
import { BASE_URI, DEALERID, OMODA_DEALER_ID } from '../Constants';
import { DealerContext } from '../contexts/DealerContext';
import RolaLogo from '../assets/Rola_Black_Logo.png';
import NavbarComponent from '../NavbarComponent';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    borderBottom: '1px solid #dfe3e5',
    zIndex: 5,
  },
  rootInner: {
    backgroundColor: 'white',
    padding: 3,
    margin: '0px auto',
    '@media (min-width: 1024px) and (max-width: 1360px)': {
      height: 85,
    },
  },
  menuHolderOuter: {
    width: '100%',
    background: 'white',
    padding: '0px 0px',
    display: 'flex',
    justifyContent: 'center',
    position: 'sticky',
    top: '0px',
    zIndex: '3',
  },
  menu: {
    width: '100%',
    maxHeight: '60px',
    display: 'flex',
    padding: '0px 40px',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
    '@media (max-width: 1330px)': {
      justifyContent: 'end',
    },
    '@media (max-width: 1340px)': {
      padding: '0px 10px',
    },
  },
  menuHolder: {
    display: 'flex',
    background: 'white',
    justifyContent: 'end',
    '@media (max-width: 1130px)': {
      display: 'none',
      paddingRight: '0px',
    },
  },

  Link: {
    lineHeight: 1.4,
    font: '1rem OmodaL',
    fontFamily: 'OmodaL',
    fontWeight: 700,
    padding: '17px 0px',
    marginLeft: '30px',
    fontSize: '16px',
    color: '#0c0c0b',
    '@media (max-width: 1024px)': {
      fontSize: '12px',
    },
    '&:hover': {
      color: '#6b777c',
      cursor: 'pointer',
    },
  },
  LinkActive: {
    lineHeight: 1.4,
    font: '1rem OmodaL',
    fontFamily: 'OmodaL',
    fontWeight: 700,
    padding: '17px 0px',
    fontSize: '16px',
    color: '#0c0c0b',
    '@media (max-width: 1024px)': {
      fontSize: '12px',
    },
    '&:hover': {
      color: '#6b777c',
      cursor: 'pointer',
    },
  },
  LinkM: {
    lineHeight: 1.4,
    fontSize: '16px',
    font: '1rem OmodaL',
    fontFamily: 'OmodaL',
    fontWeight: 700,
    padding: '19px 15px',
    color: '#0c0c0b',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  LinkText: {
    lineHeight: 1.4,
    fontSize: '16px',
    font: '1rem OmodaL',
    fontFamily: 'OmodaL',
    fontWeight: 700,
    color: '#0c0c0b',
    '&:hover': {
      cursor: 'pointer',
    },
    '@media (max-width: 1395px)': {
      fontSize: '12px',
    },
  },

  BurgerHolder: {
    position: 'relative',
    display: 'none',
    '@media (max-width: 1130px)': {
      display: 'flex',
      position: 'relative',
      minWidth: 'calc(100% / 3)',
      justifyContent: 'end',
    },
  },
  Burger: {
    display: 'none',
    '@media (max-width: 1130px)': {
      display: 'block',
    },
  },
  pattie: {
    minWidth: '20px',
    minHeight: '2px',
    margin: '4px',
    background: 'black',
    width: '20px',
  },
  img: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    '@media (max-width: 1130px)': {
      width: 'calc(100% / 3)',
      justifyContent: 'center',
    },
  },
  logo: {
    width: '150px',
    height: '50px',
    objectFit: 'cover',
    '@media (max-width: 1130px)': {
      width: '200px',
      aspectRatio: '16/1',
      objectFit: 'cover',
    },
  },
  dropDown: {
    top: ({ showRoomDropDown }) => `${showRoomDropDown}px`,
    width: '100%',
    '@media (max-width: 1024px)': {
      display: 'none',
    },
    position: 'absolute',
    transition: '0.5s all ease-in',
    padding: '0px 0px',
    zIndex: 3,
  },
  dropDownMobileOutter: {
    transform: ({ openMenu }) => `translate(${openMenu}%,0%)`,
    background: 'white',
    width: '100%',
    right: '0px',
    top: '60px',
    bottom: '0',
    position: ({ openMenu }) => `${openMenu ? 'fixed' : 'fixed'}`,
    transition: '0.5s all ease-in',
  },
  dropDownInner: {
    borderBottom: '1px solid #dfe3e5',
    paddingBottom: '48px',
    margin: '0px auto',
    display: 'flex',
    justifyContent: 'space-between',
    background: 'white',
    zIndex: 3,
  },
  dropDownInnerHolder: {
    padding: '22px 100px',
    textAlign: 'center',
    maxWidth: 'calc(100% / 3)',
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    '&:hover': {
      '&::before': {
        content: '"."',
        color: 'white',
        display: 'block',
        height: 20,
        transform: 'rotate(45deg) translate(50%,0%)',
        width: '20px',
        position: 'absolute',
        right: '50%',
        top: '-10px',
        background: 'white',
      },
    },
  },
  dropDownInnerHolderText: {
    borderLeft: '1px solid #dfe3e5',
    paddingLeft: '70px',
    fontSize: '25px',
    textAlign: 'left',
  },
  dropDownInnerHolderImg: {
    width: '250px',
    paddingRight: '70px',
  },
  dropDownInnerBanner: {
    background: 'c700f',
    margin: '0 auto',
    maxWidth: '1170px',
    display: 'flex',
  },
  dropDownInnerOutter: {
    background: 'c700f',
    margin: '0 auto',
  },
  dropDownInnerBannerModel: {
    maxWidth: 'calc(100% / 3)',
    width: '100%',
    textAlign: 'center',
    color: 'white',
  },
  showRoomDropDownHolder: {
    position: 'relative',
  },
  showRoomDropDown: {
    position: 'absolute',
    background: '#274b84',
    whiteSpace: 'nowrap',
  },
  showRoomDropDownLink: {
    padding: '16px 0px',
    color: 'black',
  },
  showRoomDropDownLinkModel: {
    padding: '16px 0px',
    color: 'white',
    cursor: 'pointer',
  },
  Close: {
    fontSize: '20px',
    color: '#6b777c',
    textAlign: 'right',
    padding: '10px',
    paddingTop: '20px',
    paddingBottom: '0px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  btnExp: {
    backgroundColor: '#2d3235',
    color: '#fff',
    fontWeight: '400',
    border: '1px solid #2d3235',
    padding: '7px 30px',
    cursor: 'pointer',
  },
  btnView: {
    width: 150,
    padding: '7px 30px',
    color: '#2d3235',
    fontWeight: '400',
    backgroundColor: ' #ffffff',
    border: '1px solid #2d3235',
    cursor: 'pointer',
  },
  btnViewE: {
    width: 150,
    padding: '7px 30px',
    color: '#fff',
    fontWeight: '400',
    backgroundColor: ' #2d3235',
    border: '1px solid #2d3235',
    cursor: 'pointer',
  },
  flexed: {
    display: 'flex',
  },
  masterLogoContainer: {
    paddingLeft: 20,
    width: '130px',
    height: '50px',
    objectFit: 'contain',
    alignSelf: 'center',
    alignItems: 'center',
    display: 'flex',
    '@media (min-width: 769px) and (max-width: 1024px)': {
      width: '200px',
    },
  },
  masterLogoImg: {
    width: '100%',
    '@media (max-width: 1024px)': {
      width: '40%',
    },
  },
}));

const TopBar = () => {
  const [openMenu, setOpenMenu] = useState(100);
  const [navBarData, setNavBarData] = useState(false);
  const [dealerData, setDealerData] = useState({});
  const [masterDealer, setMasterDealer] = useState({});
  // const { newVehiclesList } = useContext(VehicleContext);
  const { globalDealer, dealerListDirty } = useContext(DealerContext);

  useEffect(() => {
    axios({
      method: 'GET',
      url: `${BASE_URI}/dealerWebsites/${OMODA_DEALER_ID}`,
    }).then((response) => {
      setNavBarData({
        title: response.data.title,
        subTitle: response.data.subTitle,
        logo: response.data.image,
      });

      document.title = `${response.data.title}`;
    });
  }, []);

  useEffect(() => {
    setMasterDealer(dealerListDirty?.filter((d) => d.dealerId === DEALERID)[0]);
    setDealerData(dealerListDirty?.filter((d) => d.dealerId === globalDealer?.dealerId)[0]);
  }, [dealerListDirty]);

  const obj = {
    openMenu,
  };
  const classes = useStyles({ ...obj });
  // const history = useHistory();

  const handleMenu = () => {
    if (openMenu === 100) {
      setOpenMenu(0);
    } else {
      setOpenMenu(100);
    }
  };

  const goToMaster = (domain) => {
    window.location.href = domain;
  };

  const topBarLinksLeft = [
    { internalLink: true, linkTo: '/show-room', linkName: 'Models' },
    { internalLink: true, linkTo: '/promotions', linkName: 'Promotions' },
    {
      internalLink: false,
      linkTo: 'https://www.rola.co.za/cars-for-sale?dealer=32',
      linkName: 'Showroom',
    },
    { internalLink: false, linkTo: 'https://www.rola.co.za/service', linkName: 'Service' },
    { internalLink: false, linkTo: 'https://www.rola.co.za/parts', linkName: 'Parts' },
    { internalLink: false, linkTo: 'https://www.rola.co.za/finance', linkName: 'Finance' },
    { internalLink: false, linkTo: 'https://www.rola.co.za/sell-your-car', linkName: 'Sell' },
    { internalLink: true, linkTo: '/contact-us', linkName: 'Contact Us' },
  ];

  const topBarLinksRight = [{ linkTo: '/book-a-testdrive/810', linkName: 'Book a Test Drive' }];

  return (
    <div className={classes.root}>
      <NavbarComponent />
      <div className={classes.rootInner}>
        <Box className={classes.menuHolderOuter}>
          <Box className={classes.menu}>
            <div className={classes.img}>
              <Link to="/" className={classes.flexed}>
                <img alt="" src={navBarData.logo} width="100%" className={classes.logo} />
              </Link>
              <div className={classes.menuHolder}>
                {topBarLinksLeft.map((v) => {
                  if (v.linkName === 'Models') {
                    return (
                      <NavLink
                        key={v.linkName}
                        to={v.linkTo}
                        activeClassName={classes.LinkActive}
                        className={classes.Link}
                        onClick={(e) => {
                          handleMenu(e, 's');
                        }}
                      >
                        <Typography className={classes.LinkText}>{v.linkName}</Typography>
                      </NavLink>
                    );
                  }

                  if (v.internalLink) {
                    return (
                      <NavLink
                        key={v.linkName}
                        to={v.linkTo}
                        activeClassName={classes.LinkActive}
                        className={classes.Link}
                      >
                        <Typography className={classes.LinkText} onClick={() => {}}>
                          {v.linkName}
                        </Typography>
                      </NavLink>
                    );
                  }

                  return (
                    <a
                      href={v.linkTo}
                      className={classes.Link}
                      key={v.linkName}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Typography className={classes.LinkText}>{v.linkName}</Typography>
                    </a>
                  );
                })}
              </div>
            </div>

            <div className={classes.BurgerHolder}>
              {openMenu === 100 ? (
                <div
                  className={classes.Burger}
                  onClick={() => {
                    handleMenu();
                  }}
                >
                  <div className={classes.pattie} />
                  <div className={classes.pattie} />
                  <div className={classes.pattie} />
                </div>
              ) : (
                <div
                  onClick={() => {
                    handleMenu();
                  }}
                  className={classes.Close}
                >
                  <CloseIcon />
                </div>
              )}

              <div className={classes.dropDownMobileOutter}>
                {topBarLinksLeft.map((v) => {
                  if (v.internalLink)
                    <Link
                      key={v.linkName}
                      to={v.linkTo}
                      onClick={() => {
                        handleMenu();
                      }}
                    >
                      <Typography className={classes.LinkM}>{v.linkName}</Typography>
                    </Link>;
                  return (
                    <a href={v.linkTo} key={v.linkName} target="_blank" rel="noreferrer">
                      <Typography className={classes.LinkM}>{v.linkName}</Typography>
                    </a>
                  );
                })}
                {masterDealer?.dealerId !== globalDealer?.dealerId && (
                  <div
                    className={classes.LinkM}
                    onClick={() => goToMaster(`https://${masterDealer?.websiteUrl}`)}
                  >
                    <img alt="Rola_logo" className={classes.masterLogoImg} src={RolaLogo} />
                  </div>
                )}
              </div>
            </div>
            <div className={classes.menuHolder}>
              {topBarLinksRight.map((v) => (
                <NavLink
                  key={v.linkName}
                  to={v.linkTo}
                  activeClassName={classes.LinkActive}
                  className={classes.Link}
                >
                  <Typography className={classes.LinkText} onClick={() => {}}>
                    {v.linkName}
                  </Typography>
                </NavLink>
              ))}
              <a
                className={classes.Link}
                href={`tel:${dealerData?.newCarsPhoneNumber?.replaceAll(' ', '')}`}
                target="_blank"
                style={{ fontSize: '15px' }}
                rel="noreferrer"
              >
                {dealerData?.newCarsPhoneNumber}
              </a>
              {masterDealer?.dealerId !== globalDealer?.dealerId && (
                <div
                  className={classes.masterLogoContainer}
                  onClick={() => goToMaster(`https://${masterDealer?.websiteUrl}`)}
                >
                  <img alt="Rola_logo" className={classes.masterLogoImg} src={RolaLogo} />
                </div>
              )}
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default TopBar;
